import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';

import InputBox from '../../../ui/inputBox';
import Loader from '../../../ui/loader';
import { renderIcon } from '../../../../utils/renderIcon';

import './styles.scss';
import { useSelector } from 'react-redux';

const Step2 = ({ nextStep, props }) => {
    const loading = useSelector(state => state.auth.signUpLoading);

    const [same, setSame] = useState(false);
    const [email, setEmail] = useState(props.values.email || '');
    const [firstName, setFirstName] = useState(props.values.first_name || '');
    const [lastName, setLastName] = useState(props.values.last_name || '');

    useEffect(()=>{
        props.setFieldValue('billing_first_name', firstName);
        props.setFieldValue('billing_last_name', lastName);
        props.setFieldValue('billing_email', email);

    },[])

    const {
        billing_first_name,
        billing_last_name,
        billing_email,
        billing_phone,
        billing_country,
        billing_zip,
        billing_city,
        billing_state,
        billing_address,

        shipping_first_name,
        shipping_last_name,
        shipping_email,
        shipping_phone,
        shipping_country,
        shipping_zip,
        shipping_city,
        shipping_state,
        shipping_address,

    } = props.errors;


    const onSameToggle = () => {
        if(!same){
            props.setFieldValue('shipping_first_name', props.values.billing_first_name);
            props.setFieldValue('shipping_last_name', props.values.billing_last_name);
            props.setFieldValue('shipping_email', props.values.billing_email);
            props.setFieldValue('shipping_phone', props.values.billing_phone);
            props.setFieldValue('shipping_country', props.values.billing_country);
            props.setFieldValue('shipping_zip', props.values.billing_zip);
            props.setFieldValue('shipping_city', props.values.billing_city);
            props.setFieldValue('shipping_state',  props.values.billing_state);
            props.setFieldValue('shipping_address', props.values.billing_address);
        }else{
            props.setFieldValue('shipping_first_name', '');
            props.setFieldValue('shipping_last_name', '');
            props.setFieldValue('shipping_email', '');
            props.setFieldValue('shipping_phone', '');
            props.setFieldValue('shipping_country', '');
            props.setFieldValue('shipping_zip', '');
            props.setFieldValue('shipping_city', '');
            props.setFieldValue('shipping_state',  '');
            props.setFieldValue('shipping_address', '');
        }
    }

    const addSameValues = (props) => {
        const valuesArr = getArrayFromObj(props.values);
        const billingValues = sortValues( getValues(valuesArr, 'billing') );
        const shippingValues = sortValues( getValues(valuesArr, 'shipping') );

        return {
            ...props,
            values: {
                ...props.values,
                ...getObjFromArray( setSameValues(shippingValues, billingValues) ),
            },
        };
    };

    const getValues = (values, type) =>
        values.filter(([prop, value]) => checkValue(prop, type));

    const getArrayFromObj = (obj) => Object.entries(obj);

    const getObjFromArray = (arr) => Object.fromEntries(arr);

    const sortValues = (arr) => arr.sort((a, b) => a[0] - b[0]);

    const extractValueType = (value) => value.split('_').slice(0, 1).join('');

    const checkValue = (value, type) => extractValueType(value) === type;

    const setSameValues = (shippingValues, billingValues) =>
        shippingValues.map(([prop, value], idx) => [ prop, billingValues[idx][1] ]);


    return (
        <div className='step-two'>
            <Link className='back' to='/login'>
                <span className='icon'>{renderIcon('arrow')}</span>Back to Login
            </Link>
            <div className='step-two-title'>sign up</div>
            <div className='step-two-description'>Please sign up to view tracking information</div>
            <div className='sub-title'>Contact info:</div>
            <div className='sign-up'>
                <div className='input-box'>
                    <InputBox
                        label='First name'
                        icon='men'
                        name='billing_first_name'
                        type='text'
                        setFieldValue={props.setFieldValue}
                        onChange={(e) => setFirstName(e.target.value)}
                        requiredField
                        value={firstName}
                    />
                    {billing_first_name && (
                        <div className='error-field'>{billing_first_name}</div>
                    )}
                </div>
                <div className='input-box'>
                    <InputBox
                        label='Last name'
                        icon='men'
                        name='billing_last_name'
                        type='text'
                        setFieldValue={props.setFieldValue}
                        requiredField
                        onChange={(e) => setLastName(e.target.value)}
                        value={lastName}
                    />
                    {billing_last_name && (
                        <div className='error-field'>{billing_last_name}</div>
                    )}
                </div>
                <div className='input-box'>
                    <InputBox
                        label='Phone'
                        icon='phone'
                        name='billing_phone'
                        type='text'
                        setFieldValue={props.setFieldValue}
                        requiredField
                        maskPhone
                    />
                    {billing_phone && (
                        <div className='error-field'>{billing_phone}</div>
                    )}
                </div>
                <div className='input-box'>
                    <InputBox
                        label='Alt. Phone Number'
                        icon='phone'
                        name='billing_alt_phone'
                        type='text'
                        setFieldValue={props.setFieldValue}
                    />
                </div>
                <div className='input-box'>
                    <InputBox
                        label='Email'
                        icon='email'
                        name='billing_email'
                        type='text'
                        onChange={(e) => setEmail(e.target.value)}
                        setFieldValue={props.setFieldValue}
                        value={email}
                    />
                    {billing_email && (
                        <div className='error-field'>{billing_email}</div>
                    )}
                </div>
                <div className='input-box'>
                    <InputBox
                        label='Country'
                        icon='earth'
                        name='billing_country'
                        type='text'
                        setFieldValue={props.setFieldValue}
                        requiredField
                    />
                    {billing_country && (
                        <div className='error-field'>{billing_country}</div>
                    )}
                </div>
                <div className='full-width-box'>
                    <div className='input-box'>
                        <InputBox
                            label='Address line'
                            icon='house'
                            name='billing_address'
                            type='text'
                            setFieldValue={props.setFieldValue}
                            requiredField
                        />
                        {billing_address && (
                            <div className='error-field'>{billing_address}</div>
                        )}
                    </div>
                </div>
                <div className='full-width-box'>
                    <div className='input-box'>
                        <InputBox
                            label='Alt. Address line'
                            icon='house'
                            name='billing_alt_address'
                            type='text'
                            setFieldValue={props.setFieldValue}
                        />
                    </div>
                </div>
                <div className='input-box'>
                    <InputBox
                        label='Zip Code'
                        icon='zipCode'
                        name='billing_zip'
                        type='text'
                        setFieldValue={props.setFieldValue}
                        requiredField
                    />
                    {billing_zip && (
                        <div className='error-field'>{billing_zip}</div>
                    )}
                </div>
                <div className='input-box'>
                    <InputBox
                        label='City'
                        icon='city'
                        name='billing_city'
                        type='text'
                        setFieldValue={props.setFieldValue}
                        requiredField
                    />
                    {billing_city && (
                        <div className='error-field'>{billing_city}</div>
                    )}
                </div>
                <div className='full-width-box'>
                    <div className='input-box'>
                        <InputBox
                            label='State/Territory'
                            icon='state'
                            name='billing_state'
                            type='text'
                            setFieldValue={props.setFieldValue}
                            requiredField
                        />
                        {billing_state && (
                            <div className='error-field'>{billing_state}</div>
                        )}
                    </div>
                </div>
            </div>
            <div className='box-title-check'>
                <div className='sub-title'>Shipping Address:</div>
                <div className='check-field'>
                    <input
                        className='styled-checkbox'
                        id='same_address'
                        type='checkbox'
                        checked={same}
                        name='same_address'
                        onChange={() => {
                            setSame(!same);
                            props.setFieldValue('same_address', !same);
                            onSameToggle();
                        }}
                    />
                    <label htmlFor='same_address'>Same as billing address</label>
                </div>
            </div>
            <div className={classNames({ 'sign-up': true, active: same })}>
                <div className='input-box'>
                    <InputBox
                        label='First name'
                        icon='men'
                        name='shipping_first_name'
                        type='text'
                        setFieldValue={props.setFieldValue}
                    />
                    {shipping_first_name && (
                        <div className='error-field'>{shipping_first_name}</div>
                    )}
                </div>
                <div className='input-box'>
                    <InputBox
                        label='Last name'
                        icon='men'
                        name='shipping_last_name'
                        type='text'
                        setFieldValue={props.setFieldValue}
                    />
                    {shipping_last_name && (
                        <div className='error-field'>{shipping_last_name}</div>
                    )}
                </div>
                <div className='input-box'>
                    <InputBox
                        label='Phone'
                        icon='phone'
                        name='shipping_phone'
                        type='text'
                        maskPhone
                        setFieldValue={props.setFieldValue}
                    />
                    {shipping_phone && (
                        <div className='error-field'>{shipping_phone}</div>
                    )}
                </div>
                <div className='input-box'>
                    <InputBox
                        label='Alt. Phone Number'
                        icon='phone'
                        name='shipping_alt_phone'
                        type='text'
                        setFieldValue={props.setFieldValue}
                    />
                </div>
                <div className='input-box'>
                    <InputBox
                        label='Email'
                        icon='email'
                        name='shipping_email'
                        type='text'
                        setFieldValue={props.setFieldValue}
                    />
                    {shipping_email && (
                        <div className='error-field'>{shipping_email}</div>
                    )}
                </div>
                <div className='input-box'>
                    <InputBox
                        label='Country'
                        icon='earth'
                        name='shipping_country'
                        type='text'
                        setFieldValue={props.setFieldValue}
                    />
                    {shipping_country && (
                        <div className='error-field'>{shipping_country}</div>
                    )}
                </div>
                <div className='full-width-box'>
                    <div className='input-box'>
                        <InputBox
                            label='Address line'
                            icon='house'
                            name='shipping_address'
                            type='text'
                            setFieldValue={props.setFieldValue}
                        />
                        {shipping_address && (
                            <div className='error-field'>{shipping_address}</div>
                        )}
                    </div>
                </div>
                <div className='full-width-box'>
                    <div className='input-box'>
                        <InputBox
                            label='Alt. Address line'
                            icon='house'
                            name='shipping_alt_address'
                            type='text'
                            setFieldValue={props.setFieldValue}
                        />
                    </div>
                </div>
                <div className='input-box'>
                    <InputBox
                        label='Zip Code'
                        icon='zipCode'
                        name='shipping_zip'
                        type='text'
                        setFieldValue={props.setFieldValue}
                    />
                    {shipping_zip && (
                        <div className='error-field'>{shipping_zip}</div>
                    )}
                </div>
                <div className='input-box'>
                    <InputBox
                        label='City'
                        icon='city'
                        name='shipping_city'
                        type='text'
                        setFieldValue={props.setFieldValue}
                    />
                    {shipping_city && (
                        <div className='error-field'>{shipping_city}</div>
                    )}
                </div>
                <div className='full-width-box'>
                    <div className='input-box'>
                        <InputBox
                            label='State/Territory'
                            icon='state'
                            name='shipping_state'
                            type='text'
                            setFieldValue={props.setFieldValue}
                        />
                        {shipping_state && (
                            <div className='error-field'>{shipping_state}</div>
                        )}
                    </div>
                </div>
            </div>
            <div className='group'>
                <div className='btn-link'>
                    {loading && (
                        <div className='loading-square'>
                            <div className='box'>
                                <Loader />
                            </div>
                        </div>
                    )}
                    <button type='button'
                            onClick={() => nextStep( addSameValues(props) )}
                    >
                        Sign up
                    </button>
                </div>
                <div className='content-btn'>
                    <div className='sign'>
                        Already have an account? <Link to='/login'><span>Sign In</span></Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

Step2.propTypes = {
    nextStep: PropTypes.func,
    props: PropTypes.object,
};

export default Step2;
