import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as PropTypes from 'prop-types';
import moment from 'moment';

import Breadcrumbs from '../../../ui/breadcrumbs';
import HeaderTab from '../../../ui/headerTabs';
import PageContainer from '../../../ui/pageContainer';
import Pagination from '../../../ui/pagination';
import Loader from '../../../ui/loader';
import RemoveItem from '../../../ui/modals/removeItem';

import {
    optionalSettingTabs,
    heightLineTrApiAccessTable,
    getCountLines,
} from '../../../../constants/app';
import { renderIcon } from '../../../../utils/renderIcon';
import { useWindowWidth } from '../../../../utils/useWindowWidth';

import { getApiAccess, removeApiAccess, removeAllApiAccessExceptCurrent } from '../../../../actions/settings';

import './styles.scss';

const ApiAccess = ({ actions: { getApiAccess, removeApiAccess, removeAllApiAccessExceptCurrent }, apiAccess, loading }) => {
    const { records, total } = apiAccess;
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const [isRemoveAll, setRemoveAll] = useState(false);


    const table = useRef(null);
    const tableHead = useRef(null);
    const [countLines, setCountLines] = useState(0);
    const [currentPagination, setCurrentPagination] = useState(1);
    const [localApiAccess, setLocalApiAccess] = useState(records || []);
    const { width: windowWidth } = useWindowWidth();
    const companyId = JSON.parse(sessionStorage.getItem('userInfo') || localStorage.getItem('userInfo')).company_id;

    useEffect(() => {
        setLocalApiAccess([]);

        setTimeout(() => {
            setCountLines(
                getCountLines(
                    table.current.clientHeight,
                    tableHead.current.clientHeight,
                    heightLineTrApiAccessTable,
                    parseInt(getComputedStyle(tableHead.current).paddingTop)
                )
            );
            getApiAccess(
                companyId,
                getCountLines(
                    table.current.clientHeight,
                    tableHead.current.clientHeight,
                    heightLineTrApiAccessTable,
                    parseInt(getComputedStyle(tableHead.current).paddingTop)
                ),
                currentPagination
            );
        },0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPagination, windowWidth]);

    useEffect(() => {
        setLocalApiAccess(records || []);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [records ]);

    return (
        <div className='api-access'>
            {isOpenModal && (
                <RemoveItem
                  closeModal={setIsOpenModal}
                  remove={isRemoveAll ? removeAllApiAccessExceptCurrent : removeApiAccess}
                  id={!isRemoveAll && selectedId}
                />
            )}
            <div className="settings-header">
                <div className="header-container">
                    <Breadcrumbs/>
                </div>
            </div>
            <PageContainer>
                <HeaderTab optionTabs={optionalSettingTabs} activeTab={3} />
                <div className='api-detail'>
                    {loading && (
                        <div className='load-box'>
                            <Loader orange />
                        </div>
                    )}
                    <div className="table-title">
                        <div className='title'>API access log:</div>
                        <button className='remove-all-except-current-button' onClick={() => {setRemoveAll(!isRemoveAll); setIsOpenModal(!isOpenModal);}}>Remove all except current</button>
                    </div>
                    <div className='api-table' ref={table}>
                        <div className='table-head' ref={tableHead}>
                            <div className='date'>Date/Time of creation</div>
                            <div className='key'>API Key</div>
                            <div className='action'>Actions</div>
                        </div>
                        <div className='table-content'>
                            {localApiAccess && localApiAccess.map(item => (
                                <div className='line'>
                                    <div className='date'>{moment(item.date).format('MMMM DD, YYYY HH:mm A')}</div>
                                    <div className='key'>{item.token}</div>
                                    <div className='action'>
                                        <div className='linkkk' onClick={() => {setIsOpenModal(!isOpenModal); setSelectedId(item.id); }}>
                                            {renderIcon('trash')}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className='pagination-box'>
                        <Pagination
                            handleClick={setCurrentPagination}
                            total={total ? Math.ceil(parseInt(total) / countLines) : 0}
                            current={currentPagination}
                        />
                    </div>
                </div>
            </PageContainer>
        </div>
    );
};

ApiAccess.propTypes = {
    loading: PropTypes.bool,
    actions: PropTypes.shape({
        getApiAccess: PropTypes.func,
        removeApiAccess: PropTypes.func,
    }),
};

/**
 *
 * @param state
 * @returns {{apiAccess: Array, loading: boolean}}
 */
function mapStateToProps(state) {
    return {
        apiAccess: state.settings.apiAccess,
        loading: state.settings.loadingApiAccess,
    };
}

/**
 *
 * @param dispatch
 * @returns {{actions: ({getApiAccess: getApiAccess, removeApiAccess: removeApiAccess}|ActionCreator<any>|ActionCreatorsMapObject<any>)}}
 */
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            getApiAccess,
            removeApiAccess,
            removeAllApiAccessExceptCurrent,
        }, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ApiAccess);
