import * as types from '../constants';

export const initialState = {
    userInfo: {},
    error: null,
    loading: true,
    users: {},
    loadingUsers: true,
    roles: {},
    organisation: {},
    loadingAddUser: false,
    successAddUser: '',
    loadingUpdateAuthUser: false,
};

/**
 *
 * @param state
 * @param action
 * @returns {*}
 */
export default function user(state = initialState, action) {
    switch (action.type) {
        case types.FETCH_USER:
            return {
                ...state,
                loading: true,
            };
        case types.FETCH_AUTH_UPDATE_USER:
            return {
                ...state,
                loadingUpdateAuthUser: true,
            };
        case types.FETCH_AUTH_UPDATE_USER_SUCCESS:
            return {
                ...state,
                userInfo: action.payload,
                loadingUpdateAuthUser: false,
            };
        case types.FETCH_AUTH_UPDATE_USER_FAIL:
            return {
                ...state,
                error: action.payload,
                loadingUpdateAuthUser: false,
            };
        case types.FETCH_ADD_USER:
            return {
                ...state,
                loadingAddUser: true,
            };
        case types.FETCH_REMOVE_USER:
            return {
                ...state,
                loadingUsers: true,
            };
        case types.FETCH_USERS:
        case types.FETCH_GET_ROLES:
            return {
                ...state,
                loadingUsers: true,
            };
        case types.FETCH_USER_SUCCESS:
            return {
                ...state,
                userInfo: action.payload,
                loading: false,
            };
        case types.FETCH_UPDATE_USER_SUCCESS:
            const records = [...state.users.records];
            const findIndex = records.findIndex(record => record.user_id === action.payload.user_id);
            records.splice(findIndex, 1, action.payload);
            return {
                ...state,
                users: { ...state.users, records },
                loadingUsers: false,
            };
        case types.FETCH_GET_ROLES_SUCCESS:
            return {
                ...state,
                roles: action.payload,
                loadingUsers: false,
            };
        case types.FETCH_GET_ORGANISATION_SUCCESS:
            return {
                ...state,
                organisation: action.payload,
            };
        case types.FETCH_USERS_SUCCESS:
            return {
                ...state,
                users: action.payload,
                loadingUsers: false,
            };
        case types.FETCH_REMOVE_USER_SUCCESS:
            return {
                ...state,
                loadingUsers: false,
            };
        case types.FETCH_ADD_USER_SUCCESS:
            return {
                ...state,
                loadingAddUser: false,
                successAddUser: action.payload.data,
            };
        case types.FETCH_SUCCESS_ADD_USER:
            return {
                ...state,
                successAddUser: '',
            };
        case types.FETCH_USER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case types.FETCH_USERS_FAIL:
        case types.FETCH_REMOVE_USER_FAIL:
            return {
                ...state,
                loadingUsers: false,
                error: action.payload,
            };
        case types.FETCH_GET_ROLES_FAIL:
            return {
                ...state,
                loadingUsers: false,
                error: action.payload,
            };
        case types.FETCH_GET_ORGANISATION_FAIL:
            return {
                ...state,
                error: action.payload,
            }
        case types.FETCH_ADD_USER_FAIL:
            return {
                ...state,
                loadingAddUser: false,
                error: action.payload,
            };
        case types.FETCH_CLEAN_ERROR:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
};
