export const DESKTOP = 1439;
export const LAPTOP = 1023;
export const MOBILE = 768;
export const heightLineTrBillingTable = 78;
export const heightLineTrLiveActionTable = 75;
export const heightLineTrApiAccessTable = 72;
export const heightLineTrLiveActionDeviceTable = 72;
export const heightLineTrDevicesTable = 67;
export const heightLineTrSmsTable = 61;
export const heightLineTrManageTable = 53;
export const SUPER_ADMIN = 'superadmin';

export const liveActionTypeGlobal = { label: 'Global', value: 1 };
export const liveActionTypeUnit = { label: 'Per Unit', value: 0 };

export const optionalSettingTabs = [
    {
        url: '/settings/account',
        name: 'Account',
    },
    {
        url: '/settings/company-data',
        name: 'company data',
    },
    {
        url: '/settings/manage-users',
        name: 'manage users',
    },
    {
        url: '/settings/api-access',
        name: 'API access',
    },
];


export const optionalDeviceTabs = (id) => {
 return [
     {
         url: `/devices/${id}/information`,
         name: 'Device',
     },
     // {
     //     url: `/settings/${id}/company-data`,
     //     name: 'sessions',
     // },
     {
         url: `/devices/${id}/sms`,
         name: 'sms',
     },
     // {
     //     url: `/settings/${id}/api-access`,
     //     name: 'data',
     // },
     {
         url: `/devices/${id}/change-log`,
         name: 'change log',
     },
     {
         url: `/devices/${id}/other-info`,
         name: 'other info',
     },
 ];
};


export const numberWithSpaces = (x) => {
    const parts = x.toString().split('.');

    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

    return parts.join('.');
};

export const getUrlParams = () => {
    const params = {};

    window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, (m,key,value) => {
        params[key] = value;
    });

    return params;
};

export const getCountLines = (table, tableHeader, line, tableHeaderPadding = 0) => {
    let countLines = 0;

    if (window.innerWidth >= MOBILE) {
        countLines = Math.floor((table - tableHeader - tableHeaderPadding) / line);
    } else {
        countLines = 8;
    }

    return countLines;
};

export const userInfo = JSON.parse(sessionStorage.getItem('userInfo') || localStorage.getItem('userInfo'));

export const isMobile = window.screen.width <= MOBILE ? true : false;