import React from 'react';
import classNames from 'classnames';
import * as PropTypes from 'prop-types';

import './styles.scss';

const ColumnListTable = ({ values, setValues, listTable }) => {
    const handleChange = item => {
        if(values.indexOf(item) === -1) {
            setValues((prevState) => [...prevState, item]);
        }else{
            setValues(values.filter(element => element !== item));
        }
    };

    return (
        <div className='column-select'>
            <div className='name'>Column</div>
            <div className='list-show'>
                {Object.keys(listTable).map((item) => {
                    return(
                        <div
                            key={item}
                            className={classNames({ item: true, active: values.indexOf(item) !== -1 })}
                            onClick={() => handleChange(item)}
                        >{listTable[item].title}</div>
                    );
                })}
            </div>
        </div>
    );
};

ColumnListTable.propTypes = {
    values: PropTypes.array,
    setValues: PropTypes.func,
    listTable: PropTypes.object,
};

export default ColumnListTable;
