/* eslint-disable require-jsdoc */
import * as types from '../constants';
import { callApi } from '../utils/client';
import successAlert from '../utils/successAlert';

/**
 *
 * @param size
 * @param page
 * @returns {Function}
 */
export function getAllInvoice(size = 1, page = 0) {
    return dispatch => {
        dispatch({
            type: types.FETCH_INVOICE,
        });

        return callApi(`invoices?size=${size}&page=${page}`, 'GET')
            .then(json => {
                dispatch({
                    type: types.FETCH_INVOICE_SUCCESS,
                    payload: json,
                });
            }).catch(error => {
            dispatch({
                type: types.FETCH_INVOICE_FAIL,
                payload: error.message,
            });
        });
    };
}

/**
 *
 * @param id
 * @returns {Function}
 */
export function getPDF(id = 1) {
    return dispatch => {
        dispatch({
            type: types.FETCH_PDF,
        });

        return callApi(`invoices/${id}/pdf`, 'GET')
            .then((data) => {
                // console.log('data',data);
            });
    };
}

/**
 *
 * @returns {Function}
 */
export function getStatistic() {
    return dispatch => {
        dispatch({
            type: types.FETCH_STATISTIC,
        });

        return callApi('billing/statistics', 'GET')
            .then(json => {
                dispatch({
                    type: types.FETCH_STATISTIC_SUCCESS,
                    payload: json,
                });
            }).catch(error => {
            dispatch({
                type: types.FETCH_STATISTIC_FAIL,
                payload: error.message,
            });
        });
    };
}

/**
 *
 * @param nonce
 * @param callback
 * @returns {function(*): Promise<Response | never | never>}
 */
export function addCard(nonce, callback) {
    return dispatch => {
        dispatch({
            type: types.FETCH_ADD_CARD,
        });

        return callApi('billing/cards', 'POST', nonce)
            .then(json => {
                dispatch({
                    type: types.FETCH_ADD_CARD_SUCCESS,
                    payload: json,
                });
                dispatch({
                    type: types.FETCH_GET_CARDS_SUCCESS,
                    payload: json.paymentMethods,
                });
                callback();
            }).catch(error => {
            dispatch({
                type: types.FETCH_ADD_CARD_FAIL,
                payload: error,
            });
        });
    };
}

/**
 *
 * @param field
 * @param callback
 * @returns {function(*): Promise<Response | never>}
 */
export function addACH(field, updateCardShowingState) {
    return dispatch => {
        dispatch({
            type: types.FETCH_ADD_ACH,
        });
        updateCardShowingState(true);

        return callApi('billing/ach', 'POST', field)
            .then(json => {
                dispatch({
                    type: types.FETCH_ADD_ACH_SUCCESS,
                    payload: json.paymentMethods,
                });
                updateCardShowingState(false);
            }).catch(error => {
                dispatch({
                    type: types.FETCH_ADD_ACH_FAIL,
                    payload: error,
                });
            });
    };
}

/**
 *
 * @param field
 * @param callback
 * @returns {function(*): Promise<Response | never>}
 */
export function removeACH(field, updateCardShowingState) {
    return dispatch => {
        dispatch({
            type: types.FETCH_REMOVE_ACH,
        });
        updateCardShowingState(true);

        return callApi('billing/ach', 'DELETE', field)
            .then(json => {
                dispatch({
                    type: types.FETCH_REMOVE_ACH_SUCCESS,
                    payload: json.paymentMethods,
                });
                updateCardShowingState(false);
            }).catch(error => {
                dispatch({
                    type: types.FETCH_REMOVE_ACH_FAIL,
                    payload: error,
                });
            });
    };
}

/**
 *
 * @returns {function(*): Promise<Response | never | never>}
 */
export function getCards() {
    return dispatch => {
        dispatch({
            type: types.FETCH_GET_CARDS,
        });

        return callApi('billing/cards', 'GET')
            .then(json => {
                dispatch({
                    type: types.FETCH_GET_CARDS_SUCCESS,
                    payload: json,
                });
            }).catch(error => {
                dispatch({
                    type: types.FETCH_GET_CARDS_FAIL,
                    payload: error.message,
                });
            });
    };
}

/**
 *
 * @returns {function(*): Promise<Response | never | never>}
 */
export function removeCard(id) {
    return dispatch => {
        dispatch({
            type: types.FETCH_REMOVE_CARD,
        });

        return callApi(`billing/card${id}`, 'DELETE', { payment_id: id })
            .then(json => {
                dispatch({
                    type: types.FETCH_REMOVE_CARD_SUCCESS,
                    payload: json,
                });
            }).catch(error => {
                dispatch({
                    type: types.FETCH_REMOVE_CARD_FAIL,
                    payload: error,
                });
            });
    };
}

/**
 *
 * @returns {Function}
 */
export function brainTreeToken() {
    return dispatch => {
        dispatch({
            type: types.FETCH_GET_TOKEN,
        });

        return callApi('billing/token', 'GET')
            .then(json => {
                dispatch({
                    type: types.FETCH_GET_TOKEN_SUCCESS,
                    payload: json,
                });
            }).catch(error => {
            dispatch({
                type: types.FETCH_GET_TOKEN_FAIL,
                payload: error.message,
            });
        });
    };
}

/**
 *
 * @returns {Function}
 */
export function getCreditLog(size = 1, page = 0) {
    return dispatch => {
        dispatch({
            type: types.FETCH_GET_CREDIT_LOG,
        });

        return callApi(`wallet/get-billing-log?size=${size}&page=${page}`, 'GET')
            .then(json => {
                dispatch({
                    type: types.FETCH_GET_CREDIT_LOG_SUCCESS,
                    payload: json,
                });
            }).catch(error => {
                dispatch({
                    type: types.FETCH_GET_CREDIT_LOG_FAIL,
                    payload: error.message,
                });
            });
    };
}

export function getPlaidToken() {
    return dispatch => {
        dispatch({
            type: types.FETCH_GET_PLAID_TOKEN,
        });


        return callApi('billing/get-link-token', 'GET')
            .then(json => {
                dispatch({
                    type: types.FETCH_GET_PLAID_TOKEN_SUCCESS,
                    payload: json,
                });
            }).catch(() => {
                dispatch({
                    type: types.FETCH_GET_PLAID_TOKEN_FAIL,
                    error: true,
                });
            });
    };
}

export function getWallet() {
    return dispatch => {
        dispatch({
            type: types.FETCH_GET_WALLET,
        });


        return callApi('wallet', 'GET')
            .then(json => {
                dispatch({
                    type: types.FETCH_GET_WALLET_SUCCESS,
                    payload: json,
                });
            }).catch(error => {
                dispatch({
                    type: types.FETCH_GET_WALLET_FAIL,
                    payload: error.message,
                });
            });
    };
}

export function createWallet(field, closeModalOnUpdate) {
    return dispatch => {
        dispatch({
            type: types.FETCH_CREATE_WALLET,
        });
        closeModalOnUpdate();

        return callApi('wallet/up-account', 'POST', field)
            .then(json => {
                dispatch({
                    type: types.FETCH_CREATE_WALLET_SUCCESS,
                    payload: json,
                });
                dispatch({
                    type: types.FETCH_GET_WALLET_SUCCESS,
                    payload: json,
                });
            }).catch(error => {
                dispatch({
                    type: types.FETCH_GET_WALLET_FAIL,
                    payload: error.message,
                });
            });
    };
}

export function updateWallet(field, closeModalOnUpdate) {
    return dispatch => {
        dispatch({
            type: types.FETCH_UPDATE_WALLET,
        });
        closeModalOnUpdate();

return callApi('wallet', 'PUT', field)
            .then(json => {
                successAlert('Changes saved');
                dispatch({
                    type: types.FETCH_UPDATE_WALLET_SUCCESS,
                    payload: json,
                });
            }).catch(error => {
                dispatch({
                    type: types.FETCH_UPDATE_WALLET_FAIL,
                    payload: error.message,
                });
            });
    };
}


export function addWalletSuccess() {
    return dispatch => {
        dispatch({
            type: types.FETCH_SUCCESS_CREATE_WALLET,
        });
    };
}

export function getRecommendedPayment() {
    return dispatch => {
        dispatch({
            type: types.FETCH_GET_RECOMMENDED_PAYMENT,
        });

return callApi('wallet/price', 'GET')
            .then(json => {
                dispatch({
                    type: types.FETCH_GET_RECOMMENDED_PAYMENT_SUCCESS,
                    payload: json,
                });
            }).catch(error => {
                dispatch({
                    type: types.FETCH_GET_RECOMMENDED_PAYMENT_FAIL,
                    payload: error.message,
                });
            });
    };
}