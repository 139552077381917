export const FETCH_DEVICES = 'FETCH_DEVICES';
export const FETCH_DEVICES_SUCCESS = 'FETCH_DEVICES_SUCCESS';
export const FETCH_DEVICES_FAIL = 'FETCH_DEVICES_FAIL';

export const FETCH_DEVICE_STATUS = 'FETCH_DEVICE_STATUS';
export const FETCH_DEVICE_STATUS_SUCCESS = 'FETCH_DEVICE_STATUS_SUCCESS';
export const FETCH_DEVICE_STATUS_FAIL = 'FETCH_DEVICE_STATUS_FAIL';

export const FETCH_COMPANIES_DATA = 'FETCH_COMPANIES_DATA';
export const FETCH_COMPANIES_DATA_SUCCESS = 'FETCH_COMPANIES_DATA_SUCCESS';
export const FETCH_COMPANIES_DATA_FAIL = 'FETCH_COMPANIES_DATA_FAIL';

export const FETCH_CHANGE_STATUS_DEVICE = 'FETCH_CHANGE_STATUS_DEVICE';
export const FETCH_CHANGE_STATUS_DEVICE_SUCCESS = 'FETCH_CHANGE_STATUS_DEVICE_SUCCESS';
export const FETCH_CHANGE_STATUS_DEVICE_FAIL = 'FETCH_CHANGE_STATUS_DEVICE_FAIL';

export const FETCH_CHANGE_STATUS_DEVICES = 'FETCH_CHANGE_STATUS_DEVICES';
export const FETCH_CHANGE_STATUS_DEVICES_SUCCESS = 'FETCH_CHANGE_STATUS_DEVICES_SUCCESS';
export const FETCH_CHANGE_STATUS_DEVICES_FAIL = 'FETCH_CHANGE_STATUS_DEVICES_FAIL';

export const FETCH_CHANGE_COMPANY_DEVICES = 'FETCH_CHANGE_COMPANY_DEVICES';
export const FETCH_CHANGE_COMPANY_DEVICES_SUCCESS = 'FETCH_CHANGE_COMPANY_DEVICES_SUCCESS';
export const FETCH_CHANGE_COMPANY_DEVICES_FAIL = 'FETCH_CHANGE_COMPANY_DEVICES_FAIL';

export const FETCH_DEVICE = 'FETCH_DEVICE';
export const FETCH_DEVICE_SUCCESS = 'FETCH_DEVICE_SUCCESS';
export const FETCH_DEVICE_FAIL = 'FETCH_DEVICE_FAIL';

export const FETCH_ADD_DEVICE = 'FETCH_ADD_DEVICE';
export const FETCH_ADD_DEVICE_SUCCESS = 'FETCH_ADD_DEVICE_SUCCESS';
export const FETCH_ADD_DEVICE_FAIL = 'FETCH_ADD_DEVICE_FAIL';

export const FETCH_ADD_DEVICES = 'FETCH_ADD_DEVICES';
export const FETCH_ADD_DEVICES_SUCCESS = 'FETCH_ADD_DEVICES_SUCCESS';
export const FETCH_ADD_DEVICES_FAIL = 'FETCH_ADD_DEVICES_FAIL';

export const FETCH_CSV_ASSIGN_DEVICES = 'FETCH_CSV_ASSIGN_DEVICES';
export const FETCH_CSV_ASSIGN_DEVICES_SUCCESS = 'FETCH_CSV_ASSIGN_DEVICES_SUCCESS';
export const FETCH_CSV_ASSIGN_DEVICES_FAIL = 'FETCH_CSV_ASSIGN_DEVICES_FAIL';

export const FETCH_CHANGE_PLAN_DEVICE = 'FETCH_CHANGE_PLAN_DEVICE';
export const FETCH_CHANGE_PLAN_DEVICE_SUCCESS = 'FETCH_CHANGE_PLAN_DEVICE_SUCCESS';
export const FETCH_CHANGE_PLAN_DEVICE_FAIL = 'FETCH_CHANGE_PLAN_DEVICE_FAIL';

export const FETCH_GET_TAGS = 'FETCH_GET_TAGS';
export const FETCH_GET_TAGS_SUCCESS = 'FETCH_GET_TAGS_SUCCESS';
export const FETCH_GET_TAGS_FAIL = 'FETCH_GET_TAGS_FAIL';

export const FETCH_ADD_TAG = 'FETCH_ADD_TAG';
export const FETCH_ADD_TAG_SUCCESS = 'FETCH_ADD_TAG_SUCCESS';
export const FETCH_ADD_TAG_FAIL = 'FETCH_ADD_TAG_FAIL';

export const FETCH_REMOVE_TAG = 'FETCH_REMOVE_TAG';
export const FETCH_REMOVE_TAG_SUCCESS = 'FETCH_REMOVE_TAG_SUCCESS';
export const FETCH_REMOVE_TAG_FAIL = 'FETCH_REMOVE_TAG_FAIL';

export const FETCH_ADD_LIVE_ACTION = 'FETCH_ADD_LIVE_ACTION';
export const FETCH_ADD_LIVE_ACTION_SUCCESS = 'FETCH_ADD_LIVE_ACTION_SUCCESS';
export const FETCH_ADD_LIVE_ACTION_FAIL = 'FETCH_ADD_LIVE_ACTION_FAIL';

export const FETCH_GET_SMS = 'FETCH_GET_SMS';
export const FETCH_GET_SMS_SUCCESS = 'FETCH_GET_SMS_SUCCESS';
export const FETCH_GET_SMS_FAIL = 'FETCH_GET_SMS_FAIL';

export const FETCH_SEND_SMS = 'FETCH_SEND_SMS';
export const FETCH_SEND_SMS_SUCCESS = 'FETCH_SEND_SMS_SUCCESS';
export const FETCH_SEND_SMS_FAIL = 'FETCH_SEND_SMS_FAIL';
export const FETCH_RESET_SEND_SMS = 'FETCH_RESET_SEND_SMS';

export const FETCH_OTHER_INFO = 'FETCH_OTHER_INFO';
export const FETCH_OTHER_INFO_SUCCESS = 'FETCH_OTHER_INFO_SUCCESS';
export const FETCH_OTHER_INFO_FAIL = 'FETCH_OTHER_INFO_FAIL';

export const FETCH_ADD_OTHER_INFO = 'FETCH_ADD_OTHER_INFO';
export const FETCH_ADD_OTHER_INFO_SUCCESS = 'FETCH_ADD_OTHER_INFO_SUCCESS';
export const FETCH_ADD_OTHER_INFO_FAIL = 'FETCH_ADD_OTHER_INFO_FAIL';

export const FETCH_DELETE_OTHER_INFO = 'FETCH_DELETE_OTHER_INFO';
export const FETCH_DELETE_OTHER_INFO_SUCCESS = 'FETCH_DELETE_OTHER_INFO_SUCCESS';
export const FETCH_DELETE_OTHER_INFO_FAIL = 'FETCH_DELETE_OTHER_INFO_FAIL';

export const FETCH_UPDATE_OTHER_INFO = 'FETCH_UPDATE_OTHER_INFO';
export const FETCH_UPDATE_OTHER_INFO_SUCCESS = 'FETCH_UPDATE_OTHER_INFO_SUCCESS';
export const FETCH_UPDATE_OTHER_INFO_FAIL = 'FETCH_UPDATE_OTHER_INFO_FAIL';

export const FETCH_GET_MANAGE_LOG = 'FETCH_GET_MANAGE_LOG';
export const FETCH_GET_MANAGE_LOG_SUCCESS = 'FETCH_GET_MANAGE_LOG_SUCCESS';
export const FETCH_GET_MANAGE_LOG_FAIL = 'FETCH_GET_MANAGE_LOG_FAIL';

export const FETCH_REMOVE_LIVE_ACTION = 'FETCH_REMOVE_LIVE_ACTION';
export const FETCH_REMOVE_LIVE_ACTION_SUCCESS = 'FETCH_REMOVE_LIVE_ACTION_SUCCESS';
export const FETCH_REMOVE_LIVE_ACTION_FAIL = 'FETCH_REMOVE_LIVE_ACTION_FAIL';

export const FETCH_REMOVE_LIVE_ACTION_DEVICE = 'FETCH_REMOVE_LIVE_ACTION_DEVICE';
export const FETCH_REMOVE_LIVE_ACTION_DEVICE_SUCCESS = 'FETCH_REMOVE_LIVE_ACTION_DEVICE_SUCCESS';
export const FETCH_REMOVE_LIVE_ACTION_DEVICE_FAIL = 'FETCH_REMOVE_LIVE_ACTION_DEVICE_FAIL';
