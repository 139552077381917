import { renderIcon } from '../utils/renderIcon';

export default [
    {
        name: 'Devices',
        icon: () => renderIcon('devices'),
        link: '/devices',
    },
    {
        name: 'Plans',
        icon: () => renderIcon('plans'),
        link: '/plans',
    },
    {
        name: 'Billing',
        icon: () => renderIcon('billing'),
        link: '/billing',
    },

    {
        name: 'Settings',
        icon: () => renderIcon('setting'),
        link: '/settings',
    },
    {
        name: 'Live actions',
        icon: () => renderIcon('live'),
        link: '/live-actions',
    },
];
