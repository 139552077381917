import React from 'react';
import { Router, Switch, Route, Redirect } from 'react-router-dom';
import {connect} from "react-redux";
import history from './utils/history';
import { PrivateRoute } from './utils/privateRoute';
import privateRoutes, { authRoutes } from './constants/routes';

const App = (props) => {
    return (
        <Router history={history}>
            <Switch>

                {!props.userInfo.username  && authRoutes.map((routeProps) => (
                    <Route key={routeProps.path} {...routeProps} />))
                }

                {privateRoutes.map(({ path, component, exact }, index) => (
                    <PrivateRoute
                        key={index}
                        path={path}
                        component={component}
                        exact={exact}
                    />
                ))}
                <Route render={() => <Redirect to={{pathname: "/"}} />} />
            </Switch>
        </Router>
    );
};

const mapStateToProps = (state) => {
    return {
      userInfo: state.user.userInfo
    };
};

export default connect(mapStateToProps)(App);
