import * as types from '../constants';

export const initialState = {
    error: null,
    loginLoading: false,
    signUpLoading: false,
    firstStepSignUpLoading: false,
    nextStepAllowed: false,
    forgotPasswordLoading: false,
    resetPassword: false,
    createPasswordLoading: false,
    verificationLoading: false,
    forgotPassword: '',
    isOpenedVerification: false,
};

/**
 *
 * @param state
 * @param action
 * @returns {*}
 */
export default function auth(state = initialState, action) {
    switch (action.type) {
        case types.FETCH_LOGIN:
            return {
                ...state,
                loginLoading: true,
            };
        case types.FETCH_SIGN_UP:
            return {
                ...state,
                signUpLoading: true,
                nextStepAllowed: false,
            };
        case types.FETCH_SIGN_UP_FIRST_STEP:
            return {
                ...state,
                firstStepSignUpLoading: true,
            };
        case types.FETCH_FORGOT_PASSWORD:
            return {
                ...state,
                forgotPasswordLoading: true,
            };
        case types.FETCH_RESET_PASSWORD:
            return {
                ...state,
                resetPassword: true,
            };
        case types.FETCH_CHANGE_PASSWORD:
            return {
                ...state,
                createPasswordLoading: true,
            };
        case types.FETCH_VERIFICATION:
            return {
                ...state,
                verificationLoading: true,
            };
        case types.FETCH_LOGIN_SUCCESS:
            return {
                ...state,
                loginLoading: false,
            };
        case types.FETCH_SIGN_UP_FIRST_STEP_SUCCESS:
            return {
                ...state,
                firstStepSignUpLoading: false,
                nextStepAllowed: true,
                firstStepCredentials: action.payload,
            };
        case types.FETCH_SIGN_UP_SUCCESS:
            return {
                ...state,
                signUpLoading: false,
            };
        case types.FETCH_FORGOT_PASSWORD_SUCCESS:
            return {
                ...state,
                forgotPasswordLoading: false,
                forgotPassword: action.payload,
            };
        case types.FETCH_RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                resetPassword: false,
            };
        case types.FETCH_CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                createPasswordLoading: false,
            };
        case types.FETCH_VERIFICATION_SUCCESS:
            return {
                ...state,
                verificationLoading: false,
            };
        case types.FETCH_LOGIN_FAIL:
            return {
                ...state,
                loginLoading: false,
                error: action.payload,
            };

        case types.FETCH_CLEAN_LOGIN_ERROR:
            return {
                ...state,
                error: null,
            };

        case types.FETCH_SIGN_UP_FIRST_STEP_FAIL:
            return {
                ...state,
                firstStepSignUpLoading: false,
                error: action.payload,
            };
        case types.FETCH_SIGN_UP_FAIL:
            return {
                ...state,
                signUpLoading: false,
                error: action.payload,
            };
        case types.FETCH_FORGOT_PASSWORD_FAIL:
            return {
                ...state,
                forgotPasswordLoading: false,
                error: action.payload,
            };
        case types.FETCH_RESET_PASSWORD_FAIL:
            return {
                ...state,
                resetPassword: false,
                error: true,
            };
        case types.FETCH_CHANGE_PASSWORD_FAIL:
            return {
                ...state,
                createPasswordLoading: false,
                error: 'Change password error!',
            };
        case types.FETCH_VERIFICATION_FAIL:
            return {
                ...state,
                verificationLoading: false,
                error: 'Verification error!',
            };
        case types.FETCH_CLOSE_MODAL:
            return {
                ...state,
                isOpenedVerification: false,
            };
        case types.FETCH_OPEN_MODAL:
            return {
                ...state,
                isOpenedVerification: true,
            };
        default:
            return state;
    }
};