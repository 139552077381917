import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as PropTypes from 'prop-types';
import { NotificationManager } from 'react-notifications';

import PageContainer from '../../../ui/pageContainer';
import Breadcrumbs from '../../../ui/breadcrumbs';
import HeaderTab from '../../../ui/headerTabs';
import Loader from '../../../ui/loader';
import ResetPassword from './resetPassword';
import ContactInfo from './contactInfo';

import { optionalSettingTabs } from '../../../../constants/app';

import { getUser, updateAuthUser, changePassword, clearError } from '../../../../actions/user';

import './styles.scss';

const Account = ({ actions: { getUser, updateAuthUser, changePassword, clearError }, user, loading, updateLoading, error }) => {
    useEffect(() => {

        getUser(JSON.parse(sessionStorage.getItem('userInfo') || localStorage.getItem('userInfo')).user_id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (error) {
            NotificationManager.error(`${error}`, 'Error', 5000, () => {
               clearError();
            });
           clearError();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    return (
        <div className='account'>
            <div className="settings-header">
                <div className="header-container">
                    <Breadcrumbs/>
                </div>
            </div>
            <PageContainer>

                <HeaderTab optionTabs={optionalSettingTabs} activeTab={0} />
                <div className='account-detail'>
                    <div className='title'>Contact info:</div>
                    <div className='standard-box'>
                        {loading ? (
                            <div className='load-box'>
                                <Loader orange />
                            </div>
                        ) : (
                            <ContactInfo updateAuthUser={updateAuthUser} user={user} loading={updateLoading} />
                        )}
                    </div>
                    <div className='title'>Password:</div>
                    <div className='under-title'>For changing password please fill all fields below:</div>
                    <ResetPassword changePassword={changePassword} />
                </div>
            </PageContainer>
        </div>
    );
};

Account.propTypes = {
    loading: PropTypes.bool,
    updateLoading: PropTypes.bool,
    user: PropTypes.object,
    actions: PropTypes.shape({
        getUser: PropTypes.func,
        updateAuthUser: PropTypes.func,
        changePassword: PropTypes.func,
        clearError: PropTypes.func,
    }),
};

/**
 *
 * @param state
 * @returns {{updateLoading: boolean, loading: *, error: *, user: (initialState.userInfo|{}|((options: {encoding: "buffer"}) => UserInfo<Buffer>)|((options?: {encoding: string}) => UserInfo<string>)|user.userInfo|{user_id}|*)}}
 */
function mapStateToProps(state) {
    return {
        user: state.user.userInfo,
        loading: state.user.loading,
        updateLoading: state.user.loadingUpdateAuthUser,
        error: state.user.error,
    };
}

/**
 *
 * @param dispatch
 * @returns {{actions: ({updateAuthUser: updateAuthUser, getUser: getUser, changePassword: changePassword}|ActionCreator<any>|ActionCreatorsMapObject<any>)}}
 */
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            getUser,
            updateAuthUser,
            changePassword,
            clearError,
        }, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Account);
