import React, { useEffect, useRef, useState } from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';

import Modal from '../../../ui/modal';

import './styles.scss';

function useOutsideAlerter(ref, close) {
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                close();
            }
        }
        // Bind the event listener
        document.addEventListener("click", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);
}

const RemoveItem = ({ closeModal, remove, id, size, page, removeLiveActionId, deleteUser }) => {
    const wrapperRef = useRef(null);
    const [flag, setFlag] = useState(false);
    const removeId = () => {
        if(removeLiveActionId) {
            remove(id, removeLiveActionId);
        }else if(deleteUser) {
            remove(id, size, page);
        }else if(id) {
            remove(id);
        }
        else{
            remove();
        }
    };

    useEffect(() => {
        setTimeout(() => {
            setFlag(!flag);
        },100);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const close = () => {
        setFlag(!flag);
        setTimeout(() => {
            closeModal(false);
        }, 300);
    };

    useOutsideAlerter(wrapperRef, close);

    return (
        <Modal flag={flag}>
            <div className={classNames({ 'modal-warn': true, open: flag })} ref={wrapperRef}>
                <div className='title'>Are you sure you want to remove {id ? 'item' : 'items'} ?</div>
                <div className='btn-box centered'>
                    <div className='btn-cancel' onClick={() => close()}>cancel</div>
                    <div className='btn-save' onClick={() => { removeId(); close(); }}>remove</div>
                </div>
            </div>
        </Modal>
    );
};

RemoveItem.defaultProps = {
    removeLiveActionId: null,
    deleteUser: false,
};

RemoveItem.propTypes = {
    title: PropTypes.string,
    closeModal: PropTypes.func,
    remove: PropTypes.func,
    size: PropTypes.number,
    page: PropTypes.number,
    id: PropTypes.number,
    removeLiveActionId: PropTypes.number,
    deleteUser: PropTypes.bool,
};

export default RemoveItem;
