import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { signUp, signUpFirstForm } from '../../../../actions/auth';
import AuthLayout from '../authLayout';
import Step1 from './step1';
import Step2 from './step2';
import { isEmpty } from '../../../../utils/isEmpty';
import { getFilledProps } from '../../../../utils/getFilledProps';

import './styles.scss';

export const viewStep = (currentStep, props, handleSubmit) => {
    switch (currentStep) {
        case 1:
            return (
                <Step2
                    nextStep={handleSubmit}
                    props={props}
                />
            );
        case 0:
        default:
            return (
                <Step1
                    nextStep={handleSubmit}
                    props={props}
                />
            );
    }
};

const SignUp = () => {
    const dispatch = useDispatch();

    const initialValues = {
        username: '',
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        confirmPassword: '',

        billing_first_name: '',
        billing_last_name: '',
        billing_phone: '',
        billing_alt_phone: '',
        billing_email: '',
        billing_country: '',
        billing_zip: '',
        billing_city: '',
        billing_state: '',
        billing_address: '',
        billing_alt_address: '',

        shipping_first_name: '',
        shipping_last_name: '',
        shipping_phone: '',
        shipping_alt_phone: '',
        shipping_email: '',
        shipping_country: '',
        shipping_zip: '',
        shipping_city: '',
        shipping_state: '',
        shipping_address: '',
        shipping_alt_address: '',

        same_address: false,
    };

    const { nextStepAllowed } = useSelector(state => state.auth);

    const [currentStep, setCurrentStep] = useState(0);

    const nextStep = useCallback(() => {
         setCurrentStep((prevStep) => prevStep + 1);
    }, [setCurrentStep]);

    useEffect(() => {
        nextStepAllowed && nextStep();
    }, [nextStepAllowed, nextStep]);

    const Step1Schema = Yup.object().shape({
        username: Yup.string().required('User name is Required'),
        email: Yup.string().email().required('Email is Required'),
        password: Yup.string().required('Password is required').matches(
            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
            'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character'
        ),
       confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
            .required('Password confirm is required'),
    });
    const Step2Schema = Yup.object().shape({
        billing_first_name: Yup.string().required('First name is Required'),
        billing_last_name: Yup.string().required('Last name is Required'),
        billing_email: Yup.string().email().required('Email is Required'),
        billing_phone: Yup.string().required('Phone is Required'),
        billing_country: Yup.string().required('Country is Required'),
        billing_zip: Yup.string().required('Zip code is Required'),
        billing_city: Yup.string().required('City is Required'),
        billing_address: Yup.string().required('Address is Required'),

        shipping_first_name: Yup.string().required('First name is Required'),
        shipping_last_name: Yup.string().required('Last name is Required'),
        shipping_email: Yup.string().email().required('Email is Required'),
        shipping_phone: Yup.string().required('Phone is Required'),
        shipping_country: Yup.string().required('Country is Required'),
        shipping_zip: Yup.string().required('Zip code is Required'),
        shipping_city: Yup.string().required('City is Required'),
        shipping_address: Yup.string().required('Address is Required'),
        shipping_state: Yup.string().required('State is Required'),
    });
    const schemaArray = [Step1Schema, Step2Schema];

    const handleSubmit = (data) => {
        return data.validateForm().then(item => {
            if ( !isEmpty(item) ) return;

            return currentStep
                ? dispatch(signUp(data.values))
                : dispatch(signUpFirstForm( getFilledProps(data.values) ));
        });
    };

    return (
        <AuthLayout>
            <Formik
                validateOnChange={false}
                initialValues={initialValues}
                validationSchema={schemaArray[currentStep]}
                onSubmit={handleSubmit}
            >
                {props => (
                    <Form>
                        {viewStep(currentStep, props, handleSubmit)}
                    </Form>
                )}

            </Formik>
        </AuthLayout>
    );
};

export default SignUp;
