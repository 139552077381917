import React from 'react';
import { Link } from 'react-router-dom';
import * as PropTypes from 'prop-types';

import InputBox from '../../../ui/inputBox';

const Step1 = ({ nextStep, props }) => {
    const {
        username,
        email,
        password,
        confirmPassword,
    } = props.errors;

    return (
        <div className='form-box sign-up-page'>
            <div className='title'>Welcome to Mobile Asset Solutions</div>
            <div className='description'>Please sign up to view tracking information</div>
            <div className='form-name'>Create a new account</div>
            <div className='sign-up'>
                <div className='input-box'>
                    <InputBox
                        label='Username'
                        icon='men'
                        name='username'
                        type='text'
                        setFieldValue={props.setFieldValue}
                        requiredField
                    />
                    {username && (
                        <div className='error-field'>{username}</div>
                    )}
                </div>
                <div className='input-box'>
                    <InputBox
                        label='First name'
                        icon='men'
                        name='first_name'
                        type='text'
                        setFieldValue={props.setFieldValue}
                    />
                </div>
                <div className='input-box'>
                    <InputBox
                        label='Last name'
                        icon='men'
                        name='last_name'
                        type='text'
                        setFieldValue={props.setFieldValue}
                    />
                </div>
                <div className='input-box'>
                    <InputBox
                        label='Email'
                        icon='email'
                        name='email'
                        type='text'
                        setFieldValue={props.setFieldValue}
                        requiredField
                    />
                    {email && (
                        <div className='error-field'>{email}</div>
                    )}
                </div>
                <div className='input-box'>
                    <InputBox
                        label='Password'
                        icon='lockBlack'
                        name='password'
                        type='password'
                        setFieldValue={props.setFieldValue}
                        requiredField
                    />
                    {password && (
                        <div className='error-field' style={{ bottom: 'auto', top: '61px' }}>
                            {password}
                        </div>
                    )}
                </div>
                <div className='input-box'>
                    <InputBox
                        label='Confirm password'
                        icon='lockBlack'
                        name='confirmPassword'
                        type='password'
                        setFieldValue={props.setFieldValue}
                        requiredField
                    />
                    {confirmPassword && (
                        <div className='error-field'>{confirmPassword}</div>
                    )}
                </div>
            </div>
            <div className='btn-link'>
                <button type='button' onClick={() => nextStep(props)}>Next step</button>
                <div className='content-btn'>
                    <div className='sign'>
                        Go back to <Link to='/login'><span>Login</span></Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

Step1.propTypes = {
    nextStep: PropTypes.func,
    props: PropTypes.object,
};

export default Step1;
