import React from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';

import { renderIcon } from '../../../utils/renderIcon';

import './styles.scss';

const CreditCard = ({ creditName = '', info, primary, setShowBankCard = () => {}, setShowBankCardActive = () => {}, removeACH = () => {}, setShowCreditCardActive = () => {}, setLoadingValue = () => {} }) => {
    const iconWave = renderIcon(primary ? 'orangeWave' : 'blackWave');
    const updateCardShowingState = (isLoading) => {
        setLoadingValue(isLoading);
        setShowCreditCardActive(false);
    }
    return (
        <div className='card position'>
            <div className={classNames({ card__box: true, 'black-card': !primary })}>
                <div className='wave-icon'>
                    {iconWave}
                </div>
                <div className='card__name'>{creditName}</div>
                <div className='card__number'><span>****&nbsp;&nbsp;****&nbsp;&nbsp;****&nbsp;&nbsp;</span>{info.last_digits ? info.last_digits : 'false'}</div>
                {primary ? <div className='card__active-bottom'>ACTIVE</div> : <div className='card__expires'>Expires:&nbsp;{moment(info.exp_date).format('MM/YY')}</div>}
                {primary ? (
                    <div className={classNames({ trash: true })} onClick={() => removeACH({ account_id: info.id, public_token: info.token }, updateCardShowingState)}>{renderIcon('trash')}</div>
                ) : (
                    <div className={classNames({ trash: true })} onClick={() => { setShowBankCard(true); setShowBankCardActive(false); }}>{renderIcon('pen')}</div>
                )}
            </div>
        </div>
    );
};

CreditCard.defaultProps = {
    title: '',
    creditName: '',
    black: false,
    info: {
        active: '1',
        exp_date: '2022-11-01',
    },
};

CreditCard.propTypes = {
    title: PropTypes.string,
    creditName: PropTypes.string,
    setShowCard: PropTypes.func,
    setIsOpenRemoveItem: PropTypes.func,
    setDeleteId: PropTypes.func,
    black: PropTypes.bool,
    info: PropTypes.shape({
        active: PropTypes.string,
        exp_date: PropTypes.string,
        last_digits: PropTypes.string,
    }),
};

export default CreditCard;
