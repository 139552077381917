import React from 'react';

import { ReactComponent as PersonIcon } from '../assets/svg/person.svg';
import { ReactComponent as PhoneIcon } from '../assets/svg/phone.svg';
import { ReactComponent as EmailIcon } from '../assets/svg/email.svg';
import { ReactComponent as PenIcon } from '../assets/svg/pen.svg';
import { ReactComponent as OrangeWaveIcon } from '../assets/svg/orange_wave.svg';
import { ReactComponent as BlackWaveIcon } from '../assets/svg/black_wave.svg';
import { ReactComponent as MoneyIcon } from '../assets/svg/money_off.svg';
import { ReactComponent as DollarIcon } from '../assets/svg/dollar.svg';
import { ReactComponent as ArrowIcon } from '../assets/svg/arrow.svg';
import { ReactComponent as SearchIcon } from '../assets/svg/search.svg';
import { ReactComponent as DevicesIcon } from '../assets/svg/devices.svg';
import { ReactComponent as PlansIcon } from '../assets/svg/plans.svg';
import { ReactComponent as BillingIcon } from '../assets/svg/billing.svg';
import { ReactComponent as SettingIcon } from '../assets/svg/setting.svg';
import { ReactComponent as LiveIcon } from '../assets/svg/live.svg';
import { ReactComponent as LogoutIcon } from '../assets/svg/logout.svg';
import { ReactComponent as LogoIcon } from '../assets/svg/logo.svg';
import { ReactComponent as LockBlackIcon } from '../assets/svg/lock-black.svg';
import { ReactComponent as LockWhiteIcon } from '../assets/svg/lock-white.svg';
import { ReactComponent as TrashIcon } from '../assets/svg/trash.svg';
import { ReactComponent as EarthIcon } from '../assets/svg/earth.svg';
import { ReactComponent as ZipCodeIcon } from '../assets/svg/zip-code.svg';
import { ReactComponent as CityIcon } from '../assets/svg/city.svg';
import { ReactComponent as StateIcon } from '../assets/svg/state.svg';
import { ReactComponent as PencilIcon } from '../assets/svg/pencil.svg';
import { ReactComponent as RoleIcon } from '../assets/svg/role.svg';
import { ReactComponent as PermissionIcon } from '../assets/svg/permission.svg';
import { ReactComponent as HouseIcon } from '../assets/svg/house.svg';
import { ReactComponent as MoreIcon } from '../assets/svg/more.svg';
import { ReactComponent as CopyIcon } from '../assets/svg/copy.svg';
import { ReactComponent as CheckBoxIcon } from '../assets/svg/check.svg';
import { ReactComponent as StatusIcon } from '../assets/svg/status.svg';
import { ReactComponent as CloseIcon } from '../assets/svg/esc.svg';
import { ReactComponent as PlusIcon } from '../assets/svg/plus.svg';
import { ReactComponent as RoundCheckIcon } from '../assets/svg/round_check.svg';
import { ReactComponent as ArrowLiveAction } from '../assets/svg/live_action.svg';
import { ReactComponent as Reload } from '../assets/svg/reload.svg';
import { ReactComponent as Built } from '../assets/svg/built.svg';
import { ReactComponent as Card } from '../assets/svg/card.svg';
import { ReactComponent as MoneyAmount} from '../assets/svg/moneyAmount.svg';
import { ReactComponent as CardBlack} from '../assets/svg/card_black.svg';
import { ReactComponent as Add} from '../assets/svg/add.svg';
import { ReactComponent as Back} from '../assets/svg/back.svg';

export const renderIcon = (icon, className) => {
    switch (icon) {
        case 'cardBlack':
            return <CardBlack />
        case 'add':
            return <Add />
        case 'moneyAmount':
            return <MoneyAmount />
        case 'back':
            return <Back/>
        case 'men':
            return <PersonIcon />;
        case 'phone':
            return <PhoneIcon />;
        case 'email':
            return <EmailIcon />;
        case 'orangeWave':
            return <OrangeWaveIcon />;
        case 'blackWave':
            return <BlackWaveIcon />;
        case 'money':
            return <MoneyIcon />;
        case 'search':
            return <SearchIcon />;
        case 'dollar':
            return <DollarIcon />;
        case 'arrow':
            return <ArrowIcon />;
        case 'devices':
            return <DevicesIcon />;
        case 'plans':
            return <PlansIcon />;
        case 'billing':
            return <BillingIcon />;
        case 'setting':
            return <SettingIcon />;
        case 'live':
            return <LiveIcon />;
        case 'logo':
            return <LogoIcon />;
        case 'logout':
            return <LogoutIcon />;
        case 'lockBlack':
            return <LockBlackIcon />;
        case 'lockWhite':
            return <LockWhiteIcon />;
        case 'trash':
            return <TrashIcon />;
        case 'earth':
            return <EarthIcon />;
        case 'zipCode':
            return <ZipCodeIcon />;
        case 'city':
            return <CityIcon />;
        case 'state':
            return <StateIcon />;
        case 'pen':
            return <PenIcon className={className} />;
        case 'pencil':
            return <PencilIcon />;
        case 'role':
            return <RoleIcon />;
        case 'permission':
            return <PermissionIcon />;
        case 'house':
            return <HouseIcon />;
        case 'more':
            return <MoreIcon />;
        case 'copy':
            return <CopyIcon />;
        case 'checkbox':
            return <CheckBoxIcon />;
        case 'status':
            return <StatusIcon />;
        case 'close':
            return <CloseIcon />;
        case 'round-plus':
            return <PlusIcon />;
        case 'round-check':
            return <RoundCheckIcon />;
        case 'reload':
            return <Reload />;
        case 'arrow-right':
            return <ArrowLiveAction />;
        case 'bank':
            return <Built />;
        case 'card':
            return <Card />;
        default:
            return <PersonIcon />;
    }
};