import React from 'react';
import classNames from 'classnames';
import * as PropTypes from 'prop-types';

import { renderIcon } from '../../../utils/renderIcon';
import { numberWithSpaces } from '../../../constants/app';

import './styles.scss';

const CardStatistic = ({ title, price, black }) => {
    const icon = renderIcon(black ? 'dollar' : 'money');

    return (
      <div className={classNames({ 'card-statistic': true, 'black-card': black })}>
          <div className='icons'>
              {icon}
              <div className='abs-icon'>
                  {icon}
              </div>
          </div>
          <div className='info'>
              <div className='name'>{title}</div>
              {price >= 0 && (
                  <div className='price'>{numberWithSpaces(price)}</div>
              )}
          </div>
      </div>
    );
};

CardStatistic.defaultProps = {
    title: '',
    price: 0,
    black: false,
};

CardStatistic.propTypes = {
    title: PropTypes.string,
    price: PropTypes.number,
    black: PropTypes.bool,
};

export default CardStatistic;
