import React, { useEffect, useRef, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import moment from 'moment';

import Breadcrumbs from '../../../ui/breadcrumbs';
import PageContainer from '../../../ui/pageContainer';
import HeaderTab from '../../../ui/headerTabs';
import Pagination from '../../../ui/pagination';
import Loader from '../../../ui/loader';

import { getCountLines, heightLineTrSmsTable, LAPTOP, optionalDeviceTabs, heightLineTrManageTable } from '../../../../constants/app';

import { useWindowWidth } from '../../../../utils/useWindowWidth';

import { getManageLog } from '../../../../actions/devices';

import './styles.scss';

const ChangeLog = ({ match : { params : { id } }, actions: { getManageLog }, manageLog: { total, records }, loadingManageLog }) => {
    const container = useRef(null);
    const tableHeader = useRef(null);
    const [countLines, setCountLines] = useState(0);
    const [localManageLog, setLocalManageLog] = useState(records || []);
    const [currentPagination, setCurrentPagination] = useState(1);
    const { width: windowWidth } = useWindowWidth();

    useEffect(() => {
        let heightLine = 0;

        if (window.innerWidth >= LAPTOP) {
            heightLine = heightLineTrSmsTable;
        }else{
            heightLine = heightLineTrManageTable;
        }

        setCountLines(
            getCountLines(
                container.current.clientHeight,
                tableHeader.current.clientHeight,
                heightLine,
            )
        );
        getManageLog(
            id,
            getCountLines(
                container.current.clientHeight,
                tableHeader.current.clientHeight,
                heightLine,
            ),
            currentPagination
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPagination, windowWidth]);

    useEffect(() => {
        setLocalManageLog(records);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [records]);
    // the table is being converted field
    const convertField = (item) => {
        // Column 'Change'
        if (item.field == 'Status Id') {
            return 'Device status changed';
        }
        if (item.field == 'Plan Id') {
            return 'Device plan changed';
        }
        if (item.field == 'Active') {
            return 'Activity status';
        }
        if (item.field == 'Company Id') {
            return 'Change company';
        }
        // If all 'If false' then we do not change anything
        return item.field;
    };

    // the table is being converted prior_value
    const convertPriorValue = (item) => {
        // Column 'Before'
        if (item.field == 'Active') {
            return parseInt(item.prior_value) ? 'Active' : 'Inactive';
        }
        if (item.field == 'Activation Date') {
            if (item.prior_value == 'null') {
                return 'Device activation:';
            }
        }
        if (item.field == 'Activated To') {
            if (item.prior_value == 'null') {
                return 'Active to:';
            }
        }
        // If all 'If false' then we do not change anything
        return item.prior_value;
    };

    // the table is being converted value
    const convertValue = (item) => {
        // Column 'After'
        if (item.field == 'Active') {
            return parseInt(item.value) ? 'Active' : 'Inactive';
        }
        if (item.field == 'Activation Date') {
            if (item.value == "''") {
                return 'Deactivate';
            }
        }
        if (item.field == 'Activated To') {
            if (item.value == "''") {
                return 'Is on pause';
            }
        }
        // If all 'If false' then we do not change anything
        return item.value;
    };

    return (
        <div className='change-log'>
            <div className="devices-header">
                <div className="header-container">
                    <Breadcrumbs/>
                </div>
            </div>
            <PageContainer>
                <HeaderTab optionTabs={optionalDeviceTabs(id)} activeTab={2} />
                <div className='container-detail'>
                    {loadingManageLog && (
                        <div className='load-box'>
                            <Loader orange/>
                        </div>
                    )}
                    {localManageLog && localManageLog.length === 0 && (
                        <div className='load-box'>
                            There are no change log
                        </div>
                    )}
                    <div className='title-with-btn'>
                        <div className='title'>Changes</div>
                    </div>
                    <div className='under-elem' ref={container}>
                        <div className='overflow-table'>
                            <table className='change-log-table'>
                                <thead ref={tableHeader}>
                                    <tr>
                                        <th className='field'>Change</th>
                                        <th className='prior-value'>Before</th>
                                        <th>After</th>
                                        <th className='date'>Date</th>
                                        <th className='user'>User Name</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {localManageLog && localManageLog.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td className='field'>{convertField(item)}</td>
                                            <td className='prior-value'>{convertPriorValue(item)}</td>
                                            <td className='prior-value'>{convertValue(item)}</td>
                                            <td className='date'>{moment(item.date).format('MMM DD, YYYY LT')}</td>
                                            <td className='user'>{item.user}</td>
                                        </tr>
                                    );
                                })}

                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className='pagination-box'>
                        <Pagination
                            handleClick={setCurrentPagination}
                            total={total ? Math.ceil(parseInt(total) / countLines) : 0}
                            current={currentPagination}
                        />
                    </div>
                </div>
            </PageContainer>
        </div>
    );
};

ChangeLog.propTypes = {
    manageLog: PropTypes.shape({
        total: PropTypes.number,
        logs: PropTypes.array,
    }),
    loadingManageLog: PropTypes.bool,
};

/**
 *
 * @param state
 * @returns {{loadingManageLog: boolean, manageLog: (initialState.manageLog|{})}}
 */
function mapStateToProps(state) {
    return {
        loadingManageLog: state.devices.loadingManageLog,
        manageLog: state.devices.manageLog,
    };
}

/**
 *
 * @param dispatch
 * @returns {{actions: ({getManageLog: getManageLog}|ActionCreator<any>|ActionCreatorsMapObject<any>)}}
 */
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            getManageLog,
        }, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangeLog);
