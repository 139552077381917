import React, {useRef, useState, useEffect} from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';

import {renderIcon} from '../../../utils/renderIcon';

import './styles.scss';

const
    InputBox = ({
                    label,
                    name,
                    type,
                    value,
                    icon,
                    setFieldValue,
                    requiredField = false,
                    id = '',
                    maskPhone = false,
                    maskDigit = false,
                    className
                }) => {
        const inputBox = useRef(null);
        const wrapperRef = useRef(null);
        const [active, setActive] = useState(false);
        const [email, setEmail] = useState(value || '');

        useEffect(() => {
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [wrapperRef]);


        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setActive(false);
            }
        }

        const blurInput = () => {
            setActive(false);
        };
        const [valueInput, setValueInput] = useState(false);
        const handleChange = (e) => {
            e.target.value.length > 0 ? setValueInput(true) : setValueInput(false);
            setFieldValue(e.target.name, e.target.value);
        };
        const identification = () => {
            let param = name;

            if (id.length > 0) {
                param = id + name;
            }
            return param;
        };

        return (
            <div className={classNames({'box-input': true, active, 'input-error': !!className})} ref={wrapperRef}>
                {renderIcon(icon)}
                <label
                    htmlFor={identification()}
                    className={(valueInput || (value && value.length > 0 || ((inputBox.current?.value && inputBox.current?.value.length > 0) > 0))) ? 'active' : null}>
                    {label}<sup>{requiredField ? '*' : null}</sup>
                </label>
                {maskPhone ? (
                    <NumberFormat
                        format='+1 (###) ###-####'
                        ref={inputBox}
                        name={name}
                        value={value}
                        onBlur={blurInput}
                        onFocus={() => setActive(true)}
                        onChange={e => handleChange(e)}
                    />
                ) : maskDigit ? (
                    <NumberFormat
                        decimalScale='2'
                        allowNegative={false}
                        ref={inputBox}
                        name={name}
                        value={value}
                        onBlur={blurInput}
                        onFocus={() => setActive(true)}
                        onChange={e => handleChange(e)}
                    />
                ) : (
                    <input
                        className={className}
                        type={type}
                        name={name}
                        ref={inputBox}
                        onBlur={blurInput}
                        onFocus={() => setActive(true)}
                        onChange={e => {
                            handleChange(e);
                            setEmail(e.target.value);
                        }}
                        value={['email'].indexOf(name) ? email : value}
                    />
                )}
            </div>
        );
    };

InputBox.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.string,
    setFieldValue: PropTypes.func,
    value: PropTypes.any,
    requiredField: PropTypes.bool,
};

export default InputBox;
