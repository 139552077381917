import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as PropTypes from "prop-types";

import PageContainer from "../../ui/pageContainer";
import Breadcrumbs from "../../ui/breadcrumbs";
import CardStatistic from "../../ui/cardStatistic";
import CreditCard from "../../ui/creditCard";
import Loader from "../../ui/loader";
import AddCreditCard from "../../ui/addCreditCard";
import RemoveItem from "../../ui/modals/removeItem";
import WalletSettings from "../../ui/modals/walletSettings";
import WalletBalance from "../../ui/walletBalance";

import {
  getStatistic,
  getPDF,
  addCard,
  brainTreeToken,
  getCards,
  removeCard,
  addACH,
  removeACH,
  getPlaidToken,
  getWallet,
  createWallet,
  updateWallet,
  addWalletSuccess,
  getRecommendedPayment,
} from "../../../actions/billing";

import "./styles.scss";

import EmptyBankAccount from "../../ui/emptyBankAccount";
import InvoicesTable from "./InvoicesTable";
import CreditLogTable from "./CreditLogTable";

const Billing = ({
  actions: {
    getStatistic,
    addCard,
    brainTreeToken,
    getCards,
    removeCard,
    addACH,
    removeACH,
    getPlaidToken,
    getWallet,
    createWallet,
    updateWallet,
    addWalletSuccess,
    getRecommendedPayment,
  },
  wallet,
  loadingCards,
  loadingWallet,
  statistic,
  recommendedPayment,
  successAddWallet,
  token,
  cards,
  plaidToken,
  creditCard,
  statisticLoading,
  walletLoaded,
}) => {
  const [isOpenRemoveItem, setIsOpenRemoveItem] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [showBankCard, setShowBankCard] = useState(false);
  const [showBankCardActive, setShowBankCardActive] = useState(false);
  const [showCreditCardActive, setShowCreditCardActive] = useState(false);
  const [isLoading, setLoadingValue] = useState(false);
  const [isOpenWalletSettings, setIsOpenWalletSettingsModal] = useState(false);
  const [showWalletBalance, setShowWalletBalance] = useState(false);

  useEffect(() => {
    getStatistic();
    brainTreeToken();
    getPlaidToken();
    getCards();
    getWallet();
    getRecommendedPayment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (cards?.length > 0) {
      setLoadingValue(false);
      setShowBankCardActive(true);
    }
  }, [cards]);

  useEffect(() => {
    if (creditCard?.length > 0) {
      setLoadingValue(false);
      setShowCreditCardActive(true);
    }
  }, [creditCard]);

  useEffect(() => {
    wallet && setShowWalletBalance(!showWalletBalance);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wallet]);

  return (
    <div className="billing">
      {isOpenWalletSettings && (
        <WalletSettings
          recommendedPayment={
            recommendedPayment && recommendedPayment?.recommendedPayment
          }
          addWalletSuccess={addWalletSuccess}
          successAddWallet={successAddWallet}
          closeModal={setIsOpenWalletSettingsModal}
          setShowWalletBalance={setShowWalletBalance}
          showWalletBalance={showWalletBalance}
          wallet={wallet}
          cards={cards[0]}
          creditCard={creditCard[0]}
          updateWallet={updateWallet}
          createWallet={createWallet}
        />
      )}
      <div className="billing-header">
        <div className="header-container">
          <Breadcrumbs />
        </div>
      </div>
      <PageContainer>
        {isOpenRemoveItem && (
          <RemoveItem
            remove={removeCard}
            id={deleteId}
            closeModal={setIsOpenRemoveItem}
          />
        )}
        <div className="billing-container">
          <div className="billing-box">
            <div className="title">Statistics:</div>
            <div className="statistic-box">
              {statisticLoading && (
                <div className="loading-box">
                  <div className="load-center">
                    <Loader orange />
                  </div>
                </div>
              )}

              <CardStatistic
                title="Billable Units"
                price={statistic.billable}
                black
              />
              <CardStatistic
                title="Non-Billable Units"
                price={statistic.non_billable}
              />
            </div>
            <div className="title">Payment methods:</div>

            <div className="card-box">
              {loadingCards && (
                <div className="loading-box">
                  <div className="load-center">
                    <Loader orange />
                  </div>
                </div>
              )}

              <div className="card">
                <div className="name-card">Primary</div>
                {showCreditCardActive &&
                  creditCard?.length > 0 &&
                  creditCard.map((item, index) => {
                    return (
                      <CreditCard
                        info={item}
                        primary
                        key={item.id}
                        creditName="Bank account"
                        removeACH={removeACH}
                        setShowCreditCardActive={setShowCreditCardActive}
                        setLoadingValue={setLoadingValue}
                      />
                    );
                  })}

                <EmptyBankAccount
                  primary
                  setLoadingValue={setLoadingValue}
                  isLoading={isLoading}
                  creditCard={creditCard}
                  plaidToken={plaidToken}
                  addACH={addACH}
                  setShowCreditCardActive={setShowCreditCardActive}
                />
              </div>
              <div className="card">
                <div className="name-card">Backup</div>
                {showBankCardActive &&
                  cards?.length > 0 &&
                  cards?.map((item, index) => {
                    return (
                      <CreditCard
                        info={item}
                        key={index}
                        creditName="Credit card"
                        setShowBankCard={setShowBankCard}
                        setShowBankCardActive={setShowBankCardActive}
                      />
                    );
                  })}
                {showBankCard ? (
                  <AddCreditCard
                    addCard={addCard}
                    black
                    token={token}
                    setShowBankCard={setShowBankCard}
                    setShowBankCardActive={setShowBankCardActive}
                  />
                ) : (
                  <EmptyBankAccount setShowBankCard={setShowBankCard} />
                )}
              </div>
            </div>
            <div className="title">Wallet</div>
            <div className="walletWrapper">
              {(() => {
                if (loadingWallet) {
                  return (
                    <div className="loading-centered">
                      <Loader orange />
                    </div>
                  );
                }
                if (walletLoaded) {
                  return (
                    <WalletBalance
                      wallet={wallet}
                      setIsOpenWalletSettingsModal={
                        setIsOpenWalletSettingsModal
                      }
                      isOpenWalletSettingsModal={isOpenWalletSettings}
                    />
                  );
                }

                return (
                  <div className="wallet-box">
                    <div
                      className="wallet-create"
                      onClick={() => {
                        setIsOpenWalletSettingsModal(!isOpenWalletSettings);
                      }}
                    >
                      Create
                    </div>
                  </div>
                );
              })()}
            </div>
          </div>
          <InvoicesTable />
          <CreditLogTable />
        </div>
      </PageContainer>
    </div>
  );
};

Billing.propTypes = {
  actions: PropTypes.shape({
    getStatistic: PropTypes.func,
    addCard: PropTypes.func,
    brainTreeToken: PropTypes.func,
    removeACH: PropTypes.func,
    getPlaidToken: PropTypes.func,
  }),
  invoiceLoading: PropTypes.bool,
  loadingCards: PropTypes.bool,
  statisticLoading: PropTypes.bool,
  statistic: PropTypes.shape({
    billable: PropTypes.number,
    non_billable: PropTypes.number,
  }),
  pdf: PropTypes.string,
  token: PropTypes.string,
  userInfo: PropTypes.shape({
    billing_id: PropTypes.number,
  }),
  creditList: PropTypes.shape({
    totalCredit: PropTypes.number,
    logs: PropTypes.array,
  }),
};

/**
 *
 * @param state
 * @returns {{userInfo: Requireable<InferProps<{billing_id: Requireable<number>}>> | user.userInfo | {role, company_id, user_id, phone, billing_id, permissions, last_name, first_name, email, username} | props.user.userInfo | ((options: {encoding: "buffer"}) => UserInfo<Buffer>) | ((options?: {encoding: string}) => UserInfo<string>) | *, statistic: (billing.statistic|{billable, non_billable}), pdf: Requireable<string> | string, cards: (Array|HostedFields~hostedFieldsCard[]), loadingCards: Requireable<boolean> | boolean, statisticLoading: Requireable<boolean> | boolean, token: *}}
 */
function mapStateToProps(state) {
  const {
    statistic,
    statisticLoading,
    pdf,
    token,
    cards,
    creditCard,
    loadingCards,
    userInfo,
    plaidToken,
    loadingWallet,
    wallet,
    successAddWallet,
    walletLoaded,
    recommendedPayment,
  } = state.billing;

  return {
    statistic,
    statisticLoading,
    pdf,
    token,
    plaidToken,
    cards,
    creditCard,
    loadingCards,
    loadingWallet,
    wallet,
    userInfo,
    successAddWallet,
    walletLoaded,
    recommendedPayment,
  };
}

/**
 *
 * @param dispatch
 * @returns {{actions: ({addCard: addCard, getCards: getCards, getPDF: getPDF, getStatistic: getStatistic, removeCard: removeCard, brainTreeToken: brainTreeToken}|ActionCreator<any>|ActionCreatorsMapObject<any>)}}
 */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        getPlaidToken,
        getStatistic,
        getPDF,
        addCard,
        brainTreeToken,
        getCards,
        removeCard,
        addACH,
        getWallet,
        removeACH,
        createWallet,
        updateWallet,
        addWalletSuccess,
        getRecommendedPayment,
      },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Billing);
