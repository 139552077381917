import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as PropTypes from 'prop-types';
import classNames from "classnames";

import PageContainer from '../../../ui/pageContainer';
import Breadcrumbs from '../../../ui/breadcrumbs';
import HeaderTab from '../../../ui/headerTabs';
import Pagination from '../../../ui/pagination';
import Loader from '../../../ui/loader';
import AddUser from '../../../ui/modals/addUser';
import RemoveItem from '../../../ui/modals/removeItem';
import EditOrganization from "../../../ui/modals/editOrganization/EditOrganization";

import {
    getUsers,
    searchUsers,
    getRoles,
    getOrganisation,
    addUser,
    addUserSuccess,
    removeUser,
    updateUser,
} from '../../../../actions/user';

import { getCountLines, heightLineTrApiAccessTable, optionalSettingTabs, SUPER_ADMIN } from '../../../../constants/app';

import { renderIcon } from '../../../../utils/renderIcon';
import { useWindowWidth } from '../../../../utils/useWindowWidth';
import { stringShorter } from '../../../../utils/stringShorter';

import './styles.scss';

const ManageUsers = ({
    actions: {
        getUsers,
        searchUsers,
        getRoles,
            getOrganisation,
        addUser,
        addUserSuccess,
        removeUser,
        updateUser,
    },
    usersList: {
        total,
        records,
    },
    loading,
    roles,
    organisation,
    loadingAddUser,
    successAddUser,
}) => {
    const [isOpenRemoveUserModal, setIsOpenRemoveUserModal] = useState(false);
    const [isOpenAddUserModal, setIsOpenAddUserModal] = useState(false);
    const [isOpenEditOrganizationModal, setIsOpenEditOrganizationModal] = useState(false);
    const [companyInfo, setCompanyInfo] = useState({ companyId: '', organizationName: '' });
    const [selectedId, setSelectedId] = useState(null);
    const [countLines, setCountLines] = useState(0);
    const [localUsers, setLocalUsers] = useState(records || []);
    const [currentPagination, setCurrentPagination] = useState(1);
    const { width: windowWidth } = useWindowWidth();
    const table = useRef(null);
    const tableHead = useRef(null);
    const companyId = JSON.parse(sessionStorage.getItem('userInfo') || localStorage.getItem('userInfo')).company_id;
    const role = JSON.parse(sessionStorage.getItem('userInfo') || localStorage.getItem('userInfo')).role;
    const [userUpdate, setUserUpdate] = useState(null);
    const [searchInputs,setSearchInputs] = useState({first_name: '', last_name: ''})

    useEffect(() => {
        setLocalUsers([]);
        setTimeout(() => {
            setCountLines(
                10
            );
            getUsers(
                companyId,
                10,
                currentPagination
            );
        },0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPagination, windowWidth]);

    useEffect(() => {
        if(records) {
            setLocalUsers(records);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [records]);

    useEffect(() => {
        getRoles();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        companyId && getOrganisation(companyId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companyId]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setSearchInputs((prev) => ({
          ...prev,
          [name]: value,
        }));
    }

    const search = () => {
      searchUsers(companyId, 10, currentPagination, searchInputs);
    };

    const takeOrganizationInfo = ({ companyId, organizationName }) => {
      setIsOpenEditOrganizationModal(true);
        setCompanyInfo({
          organizationName: organizationName,
          companyId: companyId,
        });
    };

    console.log("searchInputs", searchInputs);

    return (
        <div className='manage-users'>
            {isOpenRemoveUserModal && (
                <RemoveItem
                    remove={removeUser}
                    id={selectedId}
                    closeModal={setIsOpenRemoveUserModal}
                    deleteUser
                    size={countLines}
                    page={currentPagination}
                />
            )}
            {isOpenAddUserModal && (
                <AddUser
                    closeModal={setIsOpenAddUserModal}
                    roles={roles}
                    organisation={organisation.records}
                    addUser={addUser}
                    id={companyId}
                    loading={loadingAddUser}
                    successAddUser={successAddUser}
                    addUserSuccess={addUserSuccess}
                    user={userUpdate}
                    updateUser={updateUser}
                />
            )}

            {isOpenEditOrganizationModal && (
            <EditOrganization
                closeModal={setIsOpenEditOrganizationModal}
                companyInfo={companyInfo}
            />
            )}
            <div className="settings-header">
                <div className="header-container">
                    <Breadcrumbs/>
                </div>
            </div>
            <PageContainer>
                <HeaderTab optionTabs={optionalSettingTabs} activeTab={2} />
                <div className='account-detail'>
                    {loading && (
                        <div className='load-box'>
                            <Loader orange />
                        </div>
                    )}
                    <div className='title-with-button'>
                        <div className='title'>{`Users: ${total}`}</div>
                        {(role === SUPER_ADMIN || role === 'admin') && (
                            <>
                                <div className='users-search'>
                                    <input name='first_name' value={searchInputs.first_name} onChange={handleChange} className='add-users' placeholder='firstName'/>
                                    <input name='last_name' value={searchInputs.last_name} onChange={handleChange} className='add-users'  placeholder='lastName'/>
                                    <div className='add-users' onClick={search}>Search</div>
                                </div>
                                <div className='add-users' onClick={() => { setUserUpdate(null); setIsOpenAddUserModal(!isOpenAddUserModal); }}>Add new</div>
                            </>
                        )}
                    </div>
                    <div className={classNames('users-table', {'scrollY': localUsers.length > 8}, {'scrollX': windowWidth < 560} )} ref={table}>
                        <div className='table-head' ref={tableHead}>
                            <div className='userName'>Username</div>
                            <div className='first-name'>First name</div>
                            <div className='last-name'>Last name</div>
                            <div className='email'>Email</div>
                            <div className='role'>Role</div>
                            <div className='organization'>Organization</div>
                            {/*<div className='permissions'>Permissions</div>*/}
                            <div className='actions'>Actions</div>
                        </div>
                        <div className={'table-content'}>
                            {localUsers && localUsers.map((item, index) => {
                                return (
                                    <div className='line' key={index}>
                                        <div className='userName'>{item.username}</div>
                                        <div className='first-name'>{item.first_name}</div>
                                        <div className='last-name'>{item.last_name}</div>
                                        <div className='email'>{stringShorter(item.email, 20)}</div>
                                        <div className='role'>{item.role}</div>
                                        {role === SUPER_ADMIN ? (
                                            <>
                                                <div className='pencil'>
                                                    {renderIcon('pencil')}
                                                </div>
                                                <div className={classNames({ 'oranization-link': true })} 
                                                    onClick={() => takeOrganizationInfo({ companyId: item.company_id, organizationName: item.company.name })}>
                                                        {item.company.name}
                                                </div>
                                            </>
                                        ) : (
                                            <div className='organization'>
                                                {item.company.name}
                                            </div>
                                        )}
                                        {/*<div className='permissions'>*/}
                                            {/*{item.permissions && Object.keys(item.permissions).map((el, index) => {*/}
                                                {/*return (*/}
                                                    {/*<div key={index} className='xsxsxs'>{ el } {(Object.keys(item.permissions).length - 1) !== index ? ',' : ''}</div>*/}
                                                {/*);*/}
                                            {/*})}*/}
                                        {/*</div>*/}
                                        <div className='action'>
                                            {role === SUPER_ADMIN && (
                                                <div className='pencil' onClick={() => {setIsOpenAddUserModal(!isOpenAddUserModal); setUserUpdate(item);
                                                }}>
                                                    {renderIcon('pencil')}
                                                </div>
                                            )}
                                            <div className='trash'
                                                 onClick={() => {
                                                     setIsOpenRemoveUserModal(!isOpenRemoveUserModal);
                                                     setSelectedId(item.user_id);
                                                 }}>
                                                {renderIcon('trash')}
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div className='pagination-box'>
                        <Pagination
                            handleClick={setCurrentPagination}
                            total={total ? Math.ceil(parseInt(total) / countLines) : 0}
                            current={currentPagination}
                        />
                    </div>
                </div>
            </PageContainer>
        </div>
    );
};

ManageUsers.propTypes = {
    actions: PropTypes.shape({
        getRoles: PropTypes.func,
        getOrganisation: PropTypes.func,
        getUsers: PropTypes.func,
        searchUsers: PropTypes.func,
        addUser: PropTypes.func,
        addUserSuccess: PropTypes.func,
        removeUser: PropTypes.func,
            updateUser: PropTypes.func,
    }),
    usersList: PropTypes.shape({
        total: PropTypes.number,
        records: PropTypes.array,
    }),
    loading: PropTypes.bool,
    loadingAddUser: PropTypes.bool,
    roles: PropTypes.object,
    successAddUser: PropTypes.string,
};

/**
 *
 * @param state
 * @returns {{successAddUser: Requireable<string> | string, usersList: Requireable<any[]> | initialState.users | {}, roles: Requireable<Object> | * | initialState.roles | {}, loadingAddUser: Requireable<boolean> | boolean, statuses: (initialState.statuses|{}|xe.statuses), loading: boolean}}
 */
function mapStateToProps(state) {
    return {
        loading: state.user.loadingUsers,
        loadingAddUser: state.user.loadingAddUser,
        usersList: state.user.users,
        statuses: state.devices.statuses,
        roles: state.user.roles,
        organisation: state.user.organisation,
        successAddUser: state.user.successAddUser,
    };
}

/**
 *
 * @param dispatch
 * @returns {{actions: ({removeUser: removeUser, getUsers: getUsers, searchUsers: searchUsers, addUser: addUser, addUserSuccess: addUserSuccess, getRoles: getRoles}|ActionCreator<any>|ActionCreatorsMapObject<any>)}}
 */
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            getUsers,
            searchUsers,
            getRoles,
            getOrganisation,
            addUser,
            addUserSuccess,
            removeUser,
            updateUser,
        }, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageUsers);
