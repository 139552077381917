import React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';

import store from './store';

import App from './App';

import './assets/styles/styles.scss';

ReactDOM.render((
    <Provider store={store}>
        <NotificationContainer />
        <App />
    </Provider>
), document.getElementById('root'));
