import React from "react";
import PageContainer from "../../../ui/pageContainer";
import FormDevice from "../formDevice";
import Breadcrumbs from "../../../ui/breadcrumbs";

import './styles.scss';

const AddDevice = () => {

    return (
        <div className="device-edit">
            <div className="devices-header">
                <div className="header-container">
                    <Breadcrumbs/>
                </div>
            </div>
            <PageContainer>
                <FormDevice/>
            </PageContainer>
        </div>
    )
}

export default AddDevice;