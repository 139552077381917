const addDot = (number) => {
    const currentNumberString = number.toString();
    const first = currentNumberString.substring(0, (currentNumberString.length - 2));
    const second = currentNumberString.substring(currentNumberString.length - 2);
    let transformedNumber;

    switch (currentNumberString.length) {
        case 2:
            transformedNumber = '0.' + first + second;
            break;
        default:
            transformedNumber = first + "." + second;
            break
    }
    return transformedNumber;
}

export default addDot;