import React from 'react';

import './index.scss';

const WalletBalance = ({wallet, setIsOpenWalletSettingsModal}) => {
    return (
        <div className='balance-wrapper'>
            <div className="balance-fields">
                <div className="currentBalance">
                    <label>Current balance:</label>
                    {wallet && wallet.amount}
                </div>
                <div className="minBalance">
                    <label>Min balance:</label>
                    {wallet && wallet.minimum_balance}
                </div>
            </div>

            <div className="active">
                <button className='edit' onClick={() => setIsOpenWalletSettingsModal(true)}>Edit</button>
            </div>
        </div>

    )

}

export default WalletBalance;