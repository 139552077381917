import React from 'react';
import { Redirect } from 'react-router-dom';

import Devices from '../components/pages/devices';
import AddDevice from '../components/pages/devices/addDevice';
import UploadDevices from '../components/pages/devices/uploadDevices';
import AssignDevices from '../components/pages/devices/assignDevices';
import LiveActions from '../components/pages/liveActions';
import CreateLiveAction from '../components/pages/liveActions/createLiveAction';
import LiveActionDetail from '../components/pages/liveActions/liveActionDetail';
import DeviceInformation from '../components/pages/device/information';
import Info from '../components/pages/device/info';
import Sms from '../components/pages/device/sms';
import ChangeLog from '../components/pages/device/changeLog';
import Plans from '../components/pages/plans';
import Billing from '../components/pages/billing';
import Account from '../components/pages/settings/account';
import CompanyData from '../components/pages/settings/companyData';
import ApiAccess from '../components/pages/settings/apiAccess';
import ManageUsers from '../components/pages/settings/manageUsers';
import Login from '../components/pages/auth/login';
import SignUp from '../components/pages/auth/signUp';
import ForgotPassword from '../components/pages/auth/forgotPassword';
import ResetPassword from '../components/pages/auth/resetPassword';
import Verification from '../components/pages/auth/verification';
import CreatePassword from '../components/pages/auth/createPassword';
import Receipt from "../components/ui/receipt";

export default [
    {
        path: '/devices/:id/information',
        component: DeviceInformation,
    },

    {
        path: '/devices/:id/sms',
        component: Sms,
    },
    {
        path: '/devices/:id/other-info',
        component: Info,
    },
    {
        path: '/devices/:id/change-log',
        component: ChangeLog,
    },
    {
        path: '/',
        exact: true,
        component: () => <Redirect to='/devices' />,
    },
    {
        path: '/devices/add_new',
        component: AddDevice,
    },
    {
        path: '/devices/upload_csv',
        component: UploadDevices,
    },
    {
        path: '/devices/assign-devices',
        component: AssignDevices,
    },
    {
        path: '/devices',
        component: Devices,
    },

    // {
    //     path: '/device/:id',
    //     component: () => <Redirect to='/devices/:id/information' />,
    // },
    {
        path: '/billing',
        component: Billing,
    },
    {
        path: '/receipt',
        component: Receipt,
    },

    {
        path: '/live-actions/new',
        component: CreateLiveAction,
    },
    {
        path: '/live-actions/:id',
        component: LiveActionDetail,
    },
    {
        path: '/live-actions',
        component: LiveActions,
    },
    {
        path: '/plans',
        component: Plans,
    },
    {
        path: '/settings/account',
        component: Account,
    },
    {
        path: '/settings/api-access',
        component: ApiAccess,
    },
    {
        path: '/settings/company-data',
        component: CompanyData,
    },
    {
        path: '/settings/manage-users',
        component: ManageUsers,
    },
    {
        path: '/settings',
        component: () => <Redirect to='/settings/account' />,
    },
];

export const authRoutes = [
    {
        path: '/login',
        component: Login,
        exact: true,
    },
    {
        path: '/signup',
        component: SignUp,
        exact: true,
    },
    {
        path: '/forgot-password',
        component: ForgotPassword,
        exact: true,
    },
    {
        path: '/reset-password',
        component: ResetPassword,
        exact: true,

    },
    {
        path: '/verify-email/:verificationToken',
        component: Verification,
        exact: true,
    },
    {
        path: '/create-password/:verificationToken',
        component: CreatePassword,
        exact: true,
    },
];
