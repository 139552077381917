import React, { useEffect, useState } from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { assignDevices } from '../../../../../../src/actions/devices';
import { getOrganisation } from '../../../../../../src/actions/user';
import {convertToFormData} from "../../fileUtils";

import SelectBox from '../../../../ui/selectBox';


const UploadCSVDevicesMobile = ({
      actions: {
          assignDevices,
          getOrganisation,
      },
      organisation = [],
}) => {
    const [selectOrganisation, setSelectOrganisation] = useState({});

    const supportedFormats = ['csv'];

    const extractExtension = (str) => {
        return str.split('.').slice(-1).join('');
    };

    useEffect(() => {
        setSelectOrganisation(organisation?.records);
    }, [organisation]);

    useEffect(() => {
        getOrganisation();
    }, [getOrganisation]);

    return (
        <Formik
            initialValues={{
                file: null,
                company: {
                    label: '',
                    value: '',
                },
            }}
            validationSchema={
                Yup.object().shape({
                    file: Yup.mixed()
                        .required('A file is required')
                        .test(
                            'format-check',
                            'only csv file is allowed',
                            value => supportedFormats.indexOf( extractExtension(value.name) ) !== -1
                        ),
                    company: Yup.object().required('Company Name is required'),
                })
            }
            onSubmit={(values, { validateForm }) => {
                validateForm()
                    .then(item => {
                        if (Object.keys(item).length === 0) {
                            assignDevices(convertToFormData(values));
                        }
                    });
            }}
        >
            {
                ({
                    submitForm,
                     ...props
                 }) => {
                    const customChangeFileInput = (event) => {
                        const { files } = event.target;

                        props.setFieldValue('file', files[0]);
                    };

                    return (
                        <Form>
                            <div className='device-box'>
                                <div className='item upload-input'>
                                    <input
                                        type='file'
                                        name='file'
                                        className='upload-file-input'
                                        id='upload-file-input'
                                        onChange={(event) => customChangeFileInput(event)}
                                    />
                                    <div className='download-box'>
                                        <a className='link-download' href = "/examples/testAssignDevice.csv" download="">Download an example</a>
                                    </div>
                                    <ErrorMessage name='file'
                                                  className='error-field'
                                                  component='div' />
                                    <SelectBox
                                        label='Company Name'
                                        name='company'
                                        active={true}
                                        values={props.values.company}
                                        icon='role'
                                        onChange={props.setFieldValue}
                                        onBlur={props.setFieldTouched}
                                        withIcon={true}
                                        stat={selectOrganisation}
                                    />
                                    <ErrorMessage name='company'
                                                  className='error-field'
                                                  component='div' />
                                </div>
                            </div>
                            <div className='btn-device'>
                                <button type='submit' className='btn'
                                        onClick={() => props.handleSubmit}>Add new</button>
                            </div>
                        </Form>
                    );
                }
            }
        </Formik>
    );
};

const mapStateToProps = (state) => ({
    organisation: state.user.organisation,
});

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({
            assignDevices,
            getOrganisation,
        }, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadCSVDevicesMobile);