import { type } from 'os';
import * as types from '../constants';

export const initialState = {
    settingsAccount: {},
    apiAccess: [],
    error: false,
    loading: true,
    loadingApiAccess: true,
    billingAddress: {},
    loadingAddress: true,
    updateAddress: false,
};

/**
 *
 * @param state
 * @param action
 * @returns {*}
 */
export default function settings(state = initialState, action) {
    switch (action.type) {
        case types.FETCH_CHANGE_PASSWORD:
        case types.FETCH_REMOVE_API_ACCESS_SUCCESS:
            return {
                ...state,
                apiAccess: {...state.apiAccess, records: state.apiAccess.records.filter(api => api.id !== action.payload)},
                loading: true,
            };
        case type.FETCH_REMOVE_API_ACCESS_SUCCESS:
            return {
                ...state,
                apiAccess: { ...state.apiAccess, records: state.apiAccess.records[0] },
                loading: false,
            };
        case types.FETCH_BILLING_ADDRESS:
            return {
                ...state,
                loadingAddress: true,
            };
        case types.FETCH_UPDATE_ADDRESS:
        case types.FETCH_DELETE_ADDRESS:
        case types.FETCH_CREATE_BILLING_ADDRESS:
        case types.FETCH_UPDATE_BILLING_CHECKBOX:
            return {
                ...state,
                updateAddress: true,
            };
        case types.FETCH_API_ACCESS:
            return {
                ...state,
                loadingApiAccess: true,
            };
        case types.FETCH_CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                settingsAccount: action.payload,
                loading: false,
            };
        case types.FETCH_API_ACCESS_SUCCESS:
            return {
                ...state,
                apiAccess: action.payload,
                error: false,
                loadingApiAccess: false,
            };
        case types.FETCH_BILLING_ADDRESS_SUCCESS:
            return {
                ...state,
                billingAddress: action.payload,
                loadingAddress: false,
            };
        case types.CLEAR_BILLING_ADDRESS:
            return {
                ...state,
                billingAddress: {},
                loadingAddress: false
            }
        case types.FETCH_CREATE_BILLING_ADDRESS_SUCCESS:
            return {
                ...state,
                billingAddress: { ...state.billingAddress, shipping: [...state.billingAddress.shipping, action.payload ] },
                updateAddress: false,
            };
        case types.FETCH_UPDATE_BILLING_CHECKBOX_SUCCESS:
            return {
                ...state,
                updateAddress: false,
                billingAddress: { ...state.billingAddress, use_billing_address: action.payload.use_billing_address },
            };
        case types.FETCH_UPDATE_ADDRESS_SUCCESS:
            const obj = {
                ...state,
                updateAddress: false,
            };

            if(action.payload.type) {
                obj['billingAddress'] = { ...state.billingAddress, shipping: { ...Object.keys(state.billingAddress.shipping).filter(item => state.billingAddress.shipping[item].id !== action.payload).map(id => state.billingAddress.shipping[id]) } };
            }else{
                obj['billingAddress'] = { ...state.billingAddress, billing: action.payload };
            }

            return obj;
        case types.FETCH_DELETE_ADDRESS_SUCCESS:
            return {
                ...state,
                billingAddress: { ...state.billingAddress, shipping: [ ...Object.keys(state.billingAddress.shipping).filter(item => state.billingAddress.shipping[item].id !== action.payload).map(id => state.billingAddress.shipping[id]) ]},
                updateAddress: false,
            };
        case types.FETCH_CHANGE_PASSWORD_FAIL:
        case types.FETCH_API_ACCESS_FAIL:
        case types.FETCH_REMOVE_ALL_API_ACCESS_EXCEPT_CURRENT_FAIL:
        case types.FETCH_REMOVE_API_ACCESS_FAIL:
        case types.FETCH_BILLING_ADDRESS_FAIL:
        case types.FETCH_UPDATE_ADDRESS_FAIL:
        case types.FETCH_CREATE_BILLING_ADDRESS_FAIL:
        case types.FETCH_DELETE_ADDRESS_FAIL:
        case types.FETCH_UPDATE_BILLING_CHECKBOX_FAIL:
            return {
                ...state,
                loading: false,
                error: true,
                loadingApiAccess: false,
                loadingManageUsers: false,
                loadingAddress: false,
                updateAddress: true,
            };
        default:
            return state;
    }
};
