import React from 'react';
import PageContainer from '../../../ui/pageContainer';
import Breadcrumbs from '../../../ui/breadcrumbs';
import UploadCSVDevices from '../uploadCSVDevices';
import {isMobile} from "../../../../constants/app";
import UploadCSVDevicesMobile from "../uploadCSVDevices/UploadCSVDevicesMobile";

const AssignDevices = () => {
    return (
        <div className='upload-devices'>
            <div className="devices-header">
                <div className="header-container">
                    <Breadcrumbs/>
                </div>
            </div>
            <PageContainer>
                {
                    isMobile
                        ?
                        <UploadCSVDevicesMobile />
                        :
                        <div className="content">
                            <UploadCSVDevices />
                        </div>
                }
            </PageContainer>
        </div>
    );
};

export default AssignDevices;