import React, { useEffect } from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';

import { brainTree } from '../../../utils/brainTree';

import './styles.scss';

const AddCreditCard = ({ addCard, token, black = false, setShowBankCard, setShowBankCardActive }) => {
    const callback = () => {
        setShowBankCard(false);
        setShowBankCardActive(true);
    };

    useEffect(() => {
        brainTree(addCard, token, callback);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[token]);

    return (
        <>
            <div className={classNames({ 'add-billing-card': true, black })}>
                <form action='/' id='my-sample-form'>
                    <div className='card-number-box'>
                        <input type='hidden' name='payment_method_nonce' />
                        <label htmlFor='card-number'>Credit card</label>
                        <div id='card-number' />
                        {/*<input id='card-number' type="text"/>*/}
                        <div className='container-border'>
                            {/*<div className='line1' />*/}
                            {/*<div className='line2' />*/}
                            {/*<div className='line3' />*/}
                            {/*<div className='line4' />*/}
                        </div>
                    </div>

                    <div className='bottom-box'>
                        <div className='expires'>
                            <label htmlFor='expiration-date'>Expires:</label>
                            <div id='expiration-date' />
                        </div>

                        <div className='cvv-box'>
                            <label htmlFor='cvv'>cvv</label>
                            <div id='cvv' />
                        </div>
                    </div>
                    <div className='form-box-btn'>
                        <div className='btn-cancel' onClick={() => { setShowBankCard(false); setShowBankCardActive(true); }}>cancel</div>
                        <input id='my-submit' type='submit' value='save' disabled />
                    </div>
                </form>
            </div>
        </>
    );
};

AddCreditCard.propTypes = {
    addCard: PropTypes.func,
    setShowBankCard: PropTypes.func,
    token: PropTypes.string,
};

export default AddCreditCard;
