import React, { useEffect, useRef, useState } from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';

import Modal from '../../modal';

function useOutsideAlerter(ref, close) {
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                close();
            }
        }
        // Bind the event listener
        document.addEventListener("click", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);
}

const Warn = ({ closeModal, text, onConfirm }) => {
    const wrapperRef = useRef(null);
    const [flag, setFlag] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setFlag(!flag);
        },100);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const close = () => {
        setFlag(!flag);
        setTimeout(() => {
            closeModal(false);
        }, 300);
    };

    useOutsideAlerter(wrapperRef, close);

    return (
        <Modal flag={flag}>
            <div className={classNames({ 'modal-warn': true, open: flag })}  ref={wrapperRef}>
                <div className='title'>{text}</div>
                <div className='btn-box'>
                    <div className='btn-cancel' onClick={() => close()}>{onConfirm ? 'cancel' : 'close'}</div>
                    {onConfirm && <div className='btn-cancel' onClick={() => onConfirm()}>confirm</div>}
                </div>
            </div>
        </Modal>
    );
};

Warn.propTypes = {
    closeModal: PropTypes.func,
    onConfirm: PropTypes.func,
    text: PropTypes.string,
};

export default Warn;
