import React, {useEffect, useState} from 'react';
import { useLocation } from 'react-router-dom';
import img from '../../../assets/img/login.png';
import './style.scss';
import logo from '../../../assets/svg/logo.svg';
import authorizationHeaders from "../../../utils/authorizationHeaders";
import moment from "moment";

const Receipt = (props) => {
    const dataURL = useLocation().pathname;
    const receiptNumber = +dataURL.slice(9, dataURL.length + 1);
    const [receiptState, setReceiptState] = useState({});
    const authHeaders = authorizationHeaders();

    useEffect(() => {
       fetch(`${process.env.REACT_APP_HOST}invoices/${receiptNumber}`, {
                headers: {
                    ...authHeaders,
                },
            })
           .then(response => {
               return response.json();
           })
           .then(data => {
               setReceiptState(data);
           })

    }, []);

    return (
        <div className='receipt-container'>
            <div className='receipt'>
                <div className='receipt-header'>
                    <img className='background'
                         src={img} alt=''/>
                    <img className='logo'
                         src={logo} alt=''/>

                </div>
                <div className='title'>
                    <div className='main-title' align='center'> Receipt from mastrack</div>
                    <div className='receipt-title' align='center'> Receipt # {receiptState.data?.id}</div>
                </div>

                <div className='amount-data'>
                    <div className='amount-paid'>
                        <div className='amount-title'>AMMOUNT PAID</div>
                        <div className='amount-sum'>${receiptState.data?.total}</div>
                    </div>

                    <div className='date-paid'>
                        <div className='date-title'>DATE PAID</div>
                        <div className='date-data'>{moment(receiptState.data?.date).format('MMMM DD, YYYY')}</div>
                    </div>
                </div>
                <div className='summary'>
                    <div className='summary-title'>
                        SUMMARY
                    </div>
                    <div className='summary-data'>
                        <div className='summary-row1'>Payment for activation device for
                            user {receiptState.first_name} {receiptState.last_name} ${receiptState.data?.total}</div>
                        <div className='summary-row2'>Amount charged ${receiptState.data?.total}</div>
                    </div>
                </div>

                <div className='feedback'>If you have any questions, contact us at <a className='link' href={'mailto:' + receiptState.email }> {receiptState.email} </a> or
                    call
                    at <a className='link' href={'tel:' + receiptState.phone}>{receiptState.phone}</a>
                </div>
                <div className="print-button-container">
                    <button onClick={window.print} className='print-button'>Print</button>
                </div>
            </div>
        </div>
    );
};

export default Receipt;