import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import rootReducer from '../reducers/rootReducer';
import createLogger from 'redux-logger';
import {composeWithDevTools} from "redux-devtools-extension";

const middlewares = [thunkMiddleware, createLogger];
const middlewareEnhancer = applyMiddleware(...middlewares);

const enhancers = [middlewareEnhancer];
const composedEnhancers = process.env.NODE_ENV === "development"? composeWithDevTools(...enhancers): compose(...enhancers);

export default createStore(rootReducer, composedEnhancers);
