import React, { useState, useEffect, useRef } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import * as PropTypes from 'prop-types';

import PageContainer from '../../../ui/pageContainer';
import Breadcrumbs from '../../../ui/breadcrumbs';
import HeaderTab from '../../../ui/headerTabs';
import Pagination from '../../../ui/pagination';
import Message from '../../../ui/modals/message';
import Loader from '../../../ui/loader';

import { getCountLines, heightLineTrSmsTable, optionalDeviceTabs } from '../../../../constants/app';

import { useWindowWidth } from '../../../../utils/useWindowWidth';

import { getSms, sendSms, resetSendSms } from '../../../../actions/devices';

import './styles.scss';

const Sms = ({ match, actions: { getSms, sendSms, resetSendSms }, sms, total, loading, loadingMessage, responseSms }) => {
    const [isOpenMessageModal, setIsOpenMessageModal] = useState(false);
    const [currentPagination, setCurrentPagination] = useState(1);
    const [countLines, setCountLines] = useState(0);
    const [localSms, setLocalSms] = useState(sms || []);
    const tableHeader = useRef(null);
    const table = useRef(null);
    const { width: windowWidth } = useWindowWidth();


    useEffect(() => {
        setLocalSms([]);
        setTimeout(() => {
            setCountLines(
                getCountLines(
                    table.current.clientHeight,
                    tableHeader.current.clientHeight,
                    heightLineTrSmsTable,
                )
            );

            getSms(
                match.params.id,
                getCountLines(
                    table.current.clientHeight,
                    tableHeader.current.clientHeight,
                    heightLineTrSmsTable
                ),
                currentPagination
            );
        },0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPagination, windowWidth]);

    useEffect(() => {
        setLocalSms(sms);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sms]);

    return (
        <div className='sms'>
            {isOpenMessageModal && (
                <Message
                    closeModal={setIsOpenMessageModal}
                    send={sendSms}
                    deviceId={match.params.id}
                    loading={loadingMessage}
                    responseSms={responseSms}
                    resetSendSms={resetSendSms}
                />
            )}
            <div className="devices-header">
                <div className="header-container">
                    <Breadcrumbs/>
                </div>
            </div>
            <PageContainer>
                <HeaderTab optionTabs={optionalDeviceTabs(match.params.id)} activeTab={1} />
                <div className='container-detail'>
                    <div className='title-with-btn'>
                        <div className='title'>Messages log</div>
                        <div className='btn-add' onClick={() => setIsOpenMessageModal(!isOpenMessageModal)}>add new</div>
                    </div>
                    <div className='under-element' ref={table}>
                        {loading && (
                            <div className='load-box'>
                                <Loader orange />
                            </div>
                        )}
                        {localSms.records && localSms.records.length === 0 && (
                            <div className='load-box'>
                                There are no messages log
                            </div>
                        )}
                        <div className='sms-table'>
                            <table>
                                <thead ref={tableHeader}>
                                    <tr>
                                        <th className='send-time'>Sent time</th>
                                        <th className='received-time'>Received time</th>
                                        <th className='iccid'>ICCID</th>
                                        <th className='status'>Status</th>
                                        <th className='send-message'>Send message</th>
                                        <th className='received-message'>Received message</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {localSms.records && localSms.records.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td className='send-time'>{moment.utc(item.sent_time !== 0 && item.sent_time * 1000).format('MM/DD/YY HH:mm')}</td>
                                                <td className='received-time'>{moment.utc(item.received_time === 0 ? null : item.received_time * 1000).format('MM/DD/YY HH:mm')}</td>
                                                <td className='iccid'>{item.meid}</td>
                                                <td className='send-message'>{item.status}</td>
                                                <td className='green'>{item.sent_message}</td>
                                                <td className='message'>{item.received_message}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className='pagination-box'>
                            <Pagination
                                handleClick={setCurrentPagination}
                                total={total ? Math.ceil(parseInt(total) / countLines) : 0}
                                current={currentPagination}
                            />
                    </div>
                </div>
            </PageContainer>
        </div>
    );
};

Sms.propTypes = {
    sms: PropTypes.object,
    total: PropTypes.number,
    loading: PropTypes.bool,
    loadingMessage: PropTypes.bool,
    responseSms: PropTypes.string,
    match: PropTypes.object,
    actions: PropTypes.shape({
        getSms: PropTypes.func,
        sendSms: PropTypes.func,
        resetSendSms: PropTypes.func,
    }),
};

/**
 *
 * @param state
 * @returns {{loadingMessage: boolean, total: number, responseSms: Requireable<(...args: any[]) => any> | sendSms | string, sms: Requireable<any[]> | Array, loading: boolean}}
 */
function mapStateToProps(state) {
    return {
        sms: state.devices.sms,
        total: state.devices.totalSms,
        loading: state.devices.loadingSms,
        loadingMessage: state.devices.loadingSendSms,
        responseSms: state.devices.sendSms,
    };
}

/**
 *
 * @param dispatch
 * @returns {{actions: ({sendSms: sendSms, resetSendSms: resetSendSms, getSms: getSms}|ActionCreator<any>|ActionCreatorsMapObject<any>)}}
 */
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            getSms,
            sendSms,
            resetSendSms,
        }, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Sms);
