import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import Pagination from '../../../ui/pagination';
import Loader from '../../../ui/loader';
import moment from 'moment';
import { bindActionCreators } from 'redux';


import { useWindowWidth } from '../../../../utils/useWindowWidth';

import { heightLineTrBillingTable, getCountLines } from '../../../../constants/app';

import {
    getCreditLog,
} from '../../../../actions/billing';

const CreditLogTable = ({
    actions: {
        getCreditLog,
    },
    loadingCreditLog,
    creditLog: { totalCredit: total, logs: creditLogs = [] },

}) => {
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(5);

    const table = useRef(null);
    const tableHeader = useRef(null);

    const { width: windowWidth } = useWindowWidth();

    useEffect(() => {
        getCreditLog(pageSize, page);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageSize, page]);

    // useEffect(() => {
    //     const invoiceRowCount = getCountLines(
    //         table.current.clientHeight,
    //         tableHeader.current.clientHeight,
    //         heightLineTrBillingTable,
    //         parseInt(getComputedStyle(tableHeader.current).paddingTop)
    //     );

    //     setPageSize(invoiceRowCount);

    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [windowWidth]);


    const convertDebitCreditStatusToText = (status) => {
        // if status = 0 return Charged, if 1 = Deposit
        return status ? 'Deposit' : 'Charged';
    };

    return (
        <div className='billing-box-credit'>
        <div className='title'>Credit Log:</div>
        <div className='under-element' ref={table}>
            {loadingCreditLog && (
                <div className='load-box'>
                    <Loader orange />
                </div>
            )}
            {!loadingCreditLog && creditLogs?.length === 0 && (
                <div className='load-box'>There are no credit log.</div>
            )}
            <div className='credit-table'>
                <table ref={tableHeader}>
                    <thead>
                        {creditLogs?.length !== 0 && (
                            <tr>
                                <th className='date'>Date</th>
                                <th className='description'>Description</th>
                                <th className='debit/credit'>Credits</th>
                                <th className='unit'>Amount</th>
                                <th className='balance'>Wallet Balance</th>
                            </tr>
                        )}
                    </thead>
                    <tbody>
                        {creditLogs?.length !== 0 && creditLogs?.map((item, index) => (
                            <tr key={index}>
                                <td className='date'>{moment(item.date_create).format('MMMM DD, YYYY')}</td>
                                <td className='description'>{item.description}</td>
                                <td className='debit/credit'>{convertDebitCreditStatusToText(item.debit_credit)}</td>
                                <td className='unit'>$ {item.pending_amount}</td>
                                <td className='balance'>$ {item.balance}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
        <div className='pagination-box'>
            <Pagination
                handleClick={setPage}
                total={total ? Math.ceil(parseInt(total) / pageSize) : 0}
                current={page}
            />
        </div>
    </div>
    );
};

// eslint-disable-next-line require-jsdoc
function mapStateToProps(state) {
    const {
        loadingCreditLog,
        creditLog,
    } = state.billing;

    return {
        loadingCreditLog,
        creditLog,
    };
}

// eslint-disable-next-line require-jsdoc
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            getCreditLog,
        }, dispatch),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(CreditLogTable);