import { combineReducers } from 'redux';
import user from './user';
import devices from './devices';
import plans from './plans';
import settings from './settings';
import auth from './auth';
import billing from './billing';
import liveAction from './liveActions';

const appReducer = combineReducers({
    user,
    devices,
    plans,
    settings,
    auth,
    billing,
    liveAction,
});

const rootReducer = (state, action) => {
    if (action.type === 'FETCH_LOGOUT_SUCCESS') {
        state = undefined;
    }

    return appReducer(state, action);
};

export default rootReducer;
