import React, {useEffect, useRef, useState} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';

import PageContainer from '../../ui/pageContainer';
import Breadcrumbs from '../../ui/breadcrumbs';
import Search from '../../ui/search';
import Pagination from '../../ui/pagination';
import Loader from '../../ui/loader';
import ColumnListTable from '../../ui/columnListTable';

import history from '../../../utils/history';
import {useWindowWidth} from '../../../utils/useWindowWidth';

import {getCountLines, heightLineTrDevicesTable} from '../../../constants/app';

import {
    getDevices,
    getDevice,
    getStatuses,
    getAllCompaniesData,
    changeStatusDevices,
    changeCompanyDevices,
    changePlanDevice,
} from '../../../actions/devices';
import {getPlans} from '../../../actions/plans';
import './styles.scss';
import ChangePlan from '../../ui/modals/changePlan/index';

const Devices = ({
                     actions: {
                         getPlans,
                         getDevice,
                         getDevices,
                         getStatuses,
                         getAllCompaniesData,
                         changePlanDevice,
                         changeStatusDevices,
                         changeCompanyDevices,
                     },
                     devicesList: {
                         records,
                         total,
                     },
                     statuses,
                     loading,
                     loadingStatus,
                     role,
                     companies,
                     plans,
                     deviceInfo,
                 }) => {
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);
    const [allChecked, setAllChecked] = useState(false);
    const [deviceCheckbox, setDeviceCheckbox] = useState([]);
    const [fixedElem, setFixedElem] = useState(false);
    const [isOpenChangePlanModal, setIsOpenChangePlanModal] = useState(false);
    const tableHeader = useRef(null);
    const table = useRef(null);
    const [currentPagination, setCurrentPagination] = useState(1);
    const [currentCompaniesPagination, setCurrentCompaniesPagination] = useState(1);
    const [countLines, setCountLines] = useState(0);
    const [listTableColumnShow, setListTableColumnShow] = useState(['sim', 'phone', 'status_id']);
    const listTable = {
        imsi: {className: 'phone', title: 'IMSI'},
        eid: {className: 'phone', title: 'EID'},
        sim: {className: 'ICCID', title: 'ICCID'},
        phone: {className: 'phone', title: 'Phone'},
        msisdn: {className: 'phone', title: 'MSISDN'},
        serial: {className: 'phone', title: 'Serial'},
        imei: {className: 'phone', title: 'IMEI'},
        ip: {className: 'phone', title: 'Ip'},
        sim_type: {className: 'phone', title: 'Sim type'},
        sim_carrier: {className: 'phone', title: 'Sim carrier'},
        plan_id: {className: 'phone', title: 'Plan id'},
        status_id: {className: 'status', title: 'Status'},
        company_name: {className: 'phone', title: 'Company name'},
    };
    const [companiesData, setCompaniesData] = useState({});
    const rowRef = useRef();

    if (['superadmin'].indexOf(role) !== -1) {
        listTable.company_name = {className: 'phone', title: 'Company name'};
    }

    const handleScrollTable = (event) => event.target.scrollLeft > 0 ? setFixedElem(true) : setFixedElem(false);
    const [localDevices, setLocalDevices] = useState(records || []);
    const [searchDevices, setSearchDevices] = useState(null);
    const [searchCompanies, setSearchCompanies] = useState(null);

    const { width: windowWidth } = useWindowWidth();

    useEffect(() => {
        setCompaniesData(companies);
    }, [companies]);


    useEffect(() => {
        if (['superadmin'].indexOf(role) !== -1) {
            currentCompaniesPagination && getAllCompaniesData(currentCompaniesPagination);
        }
    }, [role, getAllCompaniesData, currentCompaniesPagination]);

    useEffect(() => {
        const element = table.current;

        element.addEventListener('scroll', handleScrollTable);

        return () => element.removeEventListener('scroll', handleScrollTable);
    }, []);

    useEffect(() => {
        setLocalDevices([]);
        setTimeout(() => {
            setCountLines(
                getCountLines(
                    table.current.clientHeight,
                    tableHeader.current.clientHeight,
                    heightLineTrDevicesTable,
                    parseInt(getComputedStyle(tableHeader.current).paddingTop)
                )
            );

            getDevices(
                getCountLines(
                    table.current.clientHeight,
                    tableHeader.current.clientHeight,
                    heightLineTrDevicesTable
                ),
                currentPagination
            );
        }, 0);

        getStatuses();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPagination, windowWidth]);

    useEffect(() => {
        setLocalDevices(records);
        setDeviceCheckbox([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [records]);

    useEffect(() => {
        if (searchDevices !== null) {
            setCurrentPagination(1);
            getDevices(countLines, currentPagination, searchDevices);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchDevices]);

    useEffect(() => {
      if (searchCompanies !== null) {
        setCurrentCompaniesPagination(1);
        getAllCompaniesData(currentCompaniesPagination, countLines, searchCompanies);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchCompanies]);

    useEffect(() => {
        getPlans();
    }, []);

    useEffect(() => {
        if (deviceCheckbox.length == 1) {
            getDevice(deviceCheckbox[0]);
        }
    }, [deviceCheckbox]);

    useEffect(() => {
        setIsSuperAdmin(!(['superadmin'].indexOf(role) === -1));
    }, [role]);

    const handleCheckAll = () => {
        setAllChecked(!allChecked);
        setDeviceCheckbox(allChecked ? [] : records.map(item => item.id));
    };

    useEffect(() => {
        console.log('tableCrr', table.current.clientWidth)
    }, [table.current])

    const handleChange = id => {
        setAllChecked(!!allChecked);
        const newDevices = deviceCheckbox.indexOf(id) !== -1
            ? deviceCheckbox.filter(item => item !== id)
            : [...deviceCheckbox, id];

        setDeviceCheckbox(newDevices);
    };
    const handleChangeStatus = status => {
        let status_id;
        statuses.forEach((item, index) => {
            if (item === status) status_id = index;
        })
        changeStatusDevices(deviceCheckbox, status_id);
    };

    const handleChangeCompany = companyID => {
        changeCompanyDevices(deviceCheckbox, parseInt(companyID)).then(_ => {
            getDevices(countLines, currentPagination);
        });
    };

    const handleChangePlan = () => {
        setIsOpenChangePlanModal(true);
    };

    const handleAssignDevices = () => {
        history.push('/devices/assign-devices');
    };

    return (
        <div className='devices'>
            {isOpenChangePlanModal &&
            <ChangePlan
                closeModal={setIsOpenChangePlanModal}
                plans={plans.records}
                deviceId={deviceCheckbox}
                currentPlan={deviceCheckbox.length === 1 && deviceInfo.plan_info}
                changePlan={changePlanDevice}
            />
            }
            <div className="devices-header">
                <div className="header-container">
                    <Breadcrumbs/>
                    <div className='nav-table'>
                        {/*<Search setSearchRequest={setSearchDevices}/>*/}
                        <div className='options'>
                            <div className={classNames({
                                actions: true,
                                hide: role === 'admin',
                                active: true,
                                add: true,
                            })}>
                                <div className='name'>Add</div>
                                <div className='list-show'>
                                    <div className='item' onClick={() => history.push('/devices/add_new')}>
                                        Add device
                                    </div>
                                    <div className='item' onClick={() => history.push('/devices/upload_csv')}>
                                        Upload csv
                                    </div>
                                </div>
                            </div>
                            <div className='actions active'>
                                <div className='name'>Actions</div>
                                <div className='list-show'>
                                    <div className='actions-list'>
                                        <div className={classNames({
                                            'item': true,
                                            disabled: !deviceCheckbox.length,
                                        })}>Change Status
                                        </div>
                                        <div className='second-list'>
                                            {statuses !== undefined && statuses.map(item => {
                                                return (
                                                    <div className='elem' key={item}
                                                         onClick={() => handleChangeStatus(item)}>
                                                        {item}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                    {deviceCheckbox.length > 0 && (
                                        <div className={classNames({'item': true, disabled: !deviceCheckbox.length})}
                                             onClick={() => handleChangePlan()}>Change plan</div>)
                                    }
                                    {isSuperAdmin && (
                                        <div className='actions-list'>
                                            <div className='item'>Assign company</div>
                                            <div className='second-list'>
                                                {deviceCheckbox.length > 0 && (
                                                    <>
                                                        <Search setSearchRequest={setSearchCompanies} searchRequest={searchCompanies}/>
                                                        {companiesData?.records !== undefined
                                                        && companiesData?.records.map(({name, id}) => (
                                                            <div className='elem'
                                                                 key={id}
                                                                 onClick={() => handleChangeCompany(id)}
                                                            >
                                                                {name}
                                                            </div>
                                                        ))
                                                        }
                                                        <div className='pagination-companies-box'>
                                                            <Pagination
                                                                handleClick={setCurrentCompaniesPagination}
                                                                total={companies?.total ? Math.ceil(companies?.total / 10) : 0}
                                                                current={currentCompaniesPagination}
                                                            />
                                                        </div>
                                                    </>
                                                )}
                                                <div className='item bold'
                                                     onClick={() => handleAssignDevices()}>Upload csv
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className='column'>
                                <ColumnListTable
                                    values={listTableColumnShow}
                                    setValues={setListTableColumnShow}
                                    listTable={listTable}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <PageContainer>
                <div className='devices-container'>
                    {loading && loadingStatus && (
                        <div className='load-box'>
                            <Loader orange/>
                        </div>
                    )}
                    <div className='under-elem'>
                        <div className={classNames({
                            'mobile-checkbox-all': true,
                            'shadow-box': fixedElem,
                        })}>
                            <div className='checkbox-box'>
                                <input
                                    onChange={handleCheckAll}
                                    className='styled-checkbox'
                                    id='checkbox-all-mobile'
                                    type='checkbox'
                                    checked={allChecked}
                                />
                                <label htmlFor='checkbox-all-mobile'/>
                            </div>
                        </div>
                        <div className={classNames({
                            'mobile-checkbox': true,
                            'shadow-box': fixedElem,
                        })}>
                            {records && records.map((device, index) => {
                                return (
                                    <div className='checkbox-box' key={index}>
                                        <input
                                            onChange={() => handleChange(device.id)}
                                            className='styled-checkbox'
                                            id={`mobile-checkbox-${device.id}`}
                                            type='checkbox'
                                            checked={deviceCheckbox.find(item => item === device.id) || false}
                                        />
                                        <label htmlFor={`mobile-checkbox-${device.id}`}/>
                                    </div>
                                );
                            })}
                        </div>
                        <div className='content-table' ref={table}>
                            <table className='devices-table'>
                                <thead ref={tableHeader}>
                                <tr>
                                    <th className='th-checkbox'>
                                        <input
                                            onChange={handleCheckAll}
                                            className='styled-checkbox'
                                            id='checkbox-all'
                                            type='checkbox'
                                            checked={allChecked}
                                        />
                                        <label htmlFor='checkbox-all'/>
                                    </th>
                                    {Object.keys(listTable).map((item, index,array) => {
                                        if (listTableColumnShow.indexOf(item) !== -1) {
                                            return (
                                                <th key={index} className={listTable[item].className}>
                                                    {listTable[item].title}
                                                </th>
                                            );
                                        }

                                        return null;
                                    })}
                                </tr>
                                </thead>
                                <tbody>
                                {statuses !== undefined && localDevices && localDevices.map((device) => {
                                    return (
                                        <tr key={device.id}>
                                            <td className='td-checkbox'>
                                                <input
                                                    onChange={() => handleChange(device.id)}
                                                    className='styled-checkbox'
                                                    id={`checkbox-${device.id}`}
                                                    type='checkbox'
                                                    checked={deviceCheckbox.find(item => item === device.id) || false}
                                                />
                                                <label htmlFor={`checkbox-${device.id}`}/>
                                            </td>
                                            {Object.keys(listTable).map((item, index) => {
                                                if (listTableColumnShow.indexOf(item) !== -1) {
                                                    return (
                                                        <td
                                                            key={index}
                                                            onClick={() => history.push(`/devices/${device.id}/information`)}
                                                            className={classNames({
                                                                'line-td': true,
                                                                [statuses[device[item]]]: item === 'status_id',
                                                            })}
                                                        >{item === 'status_id' ? statuses[device[item]] : device[item]}</td>
                                                    );
                                                }

                                                return null;
                                            })}
                                        </tr>
                                    );
                                })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className='pagination-box'>
                        <Pagination
                            handleClick={setCurrentPagination}
                            total={total ? Math.ceil(parseInt(total) / countLines) : 0}
                            current={currentPagination}
                        />
                    </div>
                </div>
            </PageContainer>
        </div>
    );
};

Devices.propTypes = {
    actions: PropTypes.shape({
        getDevices: PropTypes.func,
        getDevice: PropTypes.func,
        getStatuses: PropTypes.func,
        getAllCompaniesData: PropTypes.func,
        changeStatusDevices: PropTypes.func,
        changeCompanyDevices: PropTypes.func,
        changePlanDevice: PropTypes.func,
    }),
    loadingStatus: PropTypes.bool,
    statuses: PropTypes.array,
    companies: PropTypes.object,
    loading: PropTypes.bool,
    role: PropTypes.string,
    plans: PropTypes.object,
    deviceInfo: PropTypes.object,
};

/**
 *
 * @param state
 * @returns {{loadingStatus: Requireable<boolean> | boolean, statuses: *, devicesList: *, loading: *}}
 */
function mapStateToProps(state) {
    return {
        plans: state.plans.plans,
        deviceInfo: state.devices.device,
        devicesList: state.devices.devices ?? { records: [], total: 0 },
        companies: state.devices.companies,
        loading: state.devices.loading,
        statuses: state.devices.statuses,
        loadingStatus: state.devices.loadingStatus,
        role: state.user.userInfo?.role,
    };
}

/**
 *
 * @param dispatch
 * @returns {{actions: ({getDevices: getDevices, getStatuses: getStatuses, changeStatusDevices: changeStatusDevices}|ActionCreator<any>|ActionCreatorsMapObject<any>)}}
 */
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            getPlans,
            getDevices,
            getDevice,
            getStatuses,
            getAllCompaniesData,
            changePlanDevice,
            changeStatusDevices,
            changeCompanyDevices,
        }, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Devices);