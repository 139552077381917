import {links, roles} from "./navLinks";

const mappings = new Map();

mappings.set(links.BILLING_PAGE, [roles.ADMIN, roles.BILLING, roles.SUPER_ADMIN])
mappings.set(links.PLANS_PAGE, [roles.ADMIN, roles.BILLING, roles.SUPER_ADMIN])
mappings.set(links.DEVICES_PAGE, [roles.ADMIN, roles.BILLING, roles.SUPER_ADMIN])
mappings.set(links.SETTINGS_PAGE, [roles.ADMIN, roles.BILLING, roles.SUPER_ADMIN])
mappings.set(links.LIVE_ACTIONS, [roles.ADMIN, roles.BILLING, roles.SUPER_ADMIN])


export function hasPermission(role, page) {
    if(mappings.has(page)) {
        return mappings.get(page).includes(role);
    }

    return false;
}