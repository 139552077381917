import React, { useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { Form, Formik, FieldArray } from 'formik';
import SelectBox from '../../../ui/selectBox';
import { renderIcon } from '../../../../utils/renderIcon';
import * as PropTypes from 'prop-types';
import _ from 'lodash';

import './styles.scss';

const LiveActionRules = ({
    remove,
    listRules,
    localRules,
    localActions,
    setIsRulesChanged,
    isRulesChanged,
    changedRulesRows,
    setChangedRulesRows,
}) => {
    const [emptyText, setEmptyText] = useState(true);
    const [changedFields, setChangedFields] = useState({});
    const isAnyChanges = Object.entries(changedFields).length > 0;

    if(!isRulesChanged && isAnyChanges) {
        setChangedFields({});
    }

    if(changedRulesRows.length === 0) {
        setIsRulesChanged(false);
    }

    const rewriteChangedRow = (index, name) => {
        const temp = changedRulesRows.filter(row => !(row.index === index && !row.hasOwnProperty(name)));

        if(temp.length === changedRulesRows.length) {
            const tempIdx = temp.map(item => item.index);
            const IndexOf = tempIdx.indexOf(index);

            temp[IndexOf] = {
                index: temp[IndexOf].index,
                [name]: temp[IndexOf][name],
            };
        };

        return temp;
    };

    const updateChangedRows = (index, value) => {
        const temp = [...changedRulesRows];
        const tempIdx = temp.map(item => item.index);
        const IndexOf = tempIdx.indexOf(index);

        if(IndexOf !== -1) {
            temp[IndexOf] = {
                ...temp[IndexOf],
                ...value,
            };
        }else{
            temp.push({ index, ...value });
        }

        return temp;
    };

    const changeSelect = ({ name, selected, newElement, rules_id, setFieldValue, index }) => {
        const newChangedFields = { ...changedFields };
        const localSelected = { ...selected };

        if(!newElement) {
            const { rule } = listRules[name.split('.')[1]];

            if(!newChangedFields.hasOwnProperty(name)) {
                // if this name is not in an array
                setIsRulesChanged(true);
                localSelected.rules_id = rules_id;
                localSelected.newElement = false;
                newChangedFields[name] = localSelected;
                setChangedRulesRows([...updateChangedRows(index, { rule: selected })]);
            }else {
                if (_.differenceBy([rule], [localSelected], 'value').length === 0 ) {
                    // when change select and change to initial
                    delete newChangedFields[name];
                    setChangedRulesRows([...rewriteChangedRow(index, 'action')]);
                } else {
                    // when change & not the same with initial
                    newChangedFields[name] = localSelected;
                    setChangedRulesRows([...changedRulesRows.map(row => {
                        if(row.index === index) {
                            row.rule = selected;
                        }

                        return row;
                    })]);
                    setIsRulesChanged(true);
                }
            }
        } else {
            setChangedRulesRows([...changedRulesRows.map(row => {
                if(row.index === index) {
                    row.rule = selected;
                }

                return row;
            })]);
            setIsRulesChanged(true);
            delete newChangedFields[name];
            localSelected.rules_id = null;
            localSelected.newElement = true;
            newChangedFields[name] = localSelected;
        }
        setIsRulesChanged(true);
        setChangedFields(newChangedFields);
        setFieldValue(name, localSelected);
    };

    const changeMultiSelect = ({ name, selected, newElement, setFieldValue, index }) => {
        const newChangedFields = { ...changedFields };

        if(!newElement) {
            const { action } = listRules[name.split('.')[1]];

            if(!newChangedFields.hasOwnProperty(name)) {
                // if this name is not in an array
                setIsRulesChanged(true);
                setChangedRulesRows([...updateChangedRows(index, {
                    index,
                    action: selected,
                })]);
                newChangedFields[name] = _.xorBy(action, selected, 'value');
            }else {
                if (_.xorBy(action, selected, 'value').length === 0 ) {
                    // when change select and change to initial
                    setChangedRulesRows([...rewriteChangedRow(index, 'rule')]);

                    delete newChangedFields[name];
                } else {
                    // when change & not the same with initial
                    setIsRulesChanged(true);
                    newChangedFields[name] = _.xorBy(action, selected, 'value');
                    setChangedRulesRows([...changedRulesRows.map(row => {
                        if(row.index === index) {
                            row.action = selected;
                        }

                        return row;
                    })]);
                }
            }
        }else {
            setChangedRulesRows([...changedRulesRows.map(row => {
                if(row.index === index) {
                    row.action = selected;
                }

                return row;
            })]);
            setIsRulesChanged(true);
            delete newChangedFields[name];
            newChangedFields[name] = selected;
        }
        setChangedFields(newChangedFields);
        setFieldValue(name, selected);
    };

    const addLine = (name, selected) => {
        const newChanged = {
            ...changedFields,
            [name]: { ...selected },
        };

        setChangedRulesRows([...changedRulesRows, {
            ...selected,
            index: Number.parseInt(name.split('.')[1]),
        }]);

        setChangedFields(newChanged);

        setIsRulesChanged(true);
    };

    return(
        <Formik
        initialValues={{
            listRules,
            line_id: null,
            delete_id: null,
        }}
        enableReinitialize
    >
        {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              setFieldValue,
              setFieldTouched,
              submitForm,
          }) => (
            <Form>
                <FieldArray
                    name='listRules'
                    render={arrayHelpers => (
                        <div className='box-rule'>
                            <div className='title-with-btn'>
                                <span className='title'>Rules/Actions:</span>
                                <button
                                    className='btn-add'
                                    onClick={() => {
                                        arrayHelpers.push({
                                            rule: { value: '', label: '', rules_id: null },
                                            rules_id: null,
                                            newElement: true,
                                            action: [],
                                        });
                                        addLine(
                                            `listRules.${values.listRules.length}.rule`,
                                            {
                                                action: [],
                                                rule: {},
                                                newElement: true },
                                            setFieldValue
                                        );
                                        setEmptyText(false);
                                    }}
                                >
                                    add new
                                </button>
                            </div>
                            {listRules.length === 0 && emptyText && (
                                <p className='empty-list'>There are no rules/actions. For add rules/actions click button "add new"</p>
                            )}
                            <div className='rule-list'>
                                {values.listRules.map((item, index) => {
                                    return (
                                        <div className='rule' key={item.rules_id}>
                                            <div className='input-box rule-select'>
                                                <SelectBox
                                                    values={values.listRules[index].rule}
                                                    name={`listRules.${index}.rule`}
                                                    onChange={(name, value) => {
                                                        changeSelect({
                                                            name,
                                                            selected: value,
                                                            newElement: values.listRules[index].newElement,
                                                            rules_id: values.listRules[index].rules_id,
                                                            setFieldValue,
                                                            index,
                                                        });
                                                    }}
                                                    onBlur={setFieldTouched}
                                                    defaultSelect
                                                    height={34}
                                                    paddingLeft={5}
                                                    paddingTop={1}
                                                    backgroundColor={'#fff'}
                                                    marginTop={-1}
                                                    statuses={localRules}
                                                />
                                            </div>
                                            <div className='line-between-selects'/>
                                            <div className='input-box'>
                                                <SelectBox
                                                    values={values.listRules[index].action}
                                                    name={`listRules.${index}.action`}
                                                    onChange={(name, value) => {
                                                        changeMultiSelect({
                                                            name,
                                                            selected: value,
                                                            newElement: values.listRules[index].newElement,
                                                            setFieldValue,
                                                            index });
                                                    }}
                                                    onBlur={setFieldTouched}
                                                    defaultSelect
                                                    height={'auto'}
                                                    paddingLeft={5}
                                                    paddingTop={1}
                                                    arrowDown={false}
                                                    isMulti
                                                    isClearable={false}
                                                    backgroundColor={'#fff'}
                                                    marginTop={-1}
                                                    statuses={localActions}
                                                />
                                            </div>
                                            <div className='option'>
                                                <span
                                                    data-tip='Copy'
                                                    onClick={() => {
                                                        arrayHelpers.push({
                                                            rule: values.listRules[index].rule,
                                                            newElement: true,
                                                            action: values.listRules[index].action,
                                                        });
                                                        addLine(
                                                            `listRules.${values.listRules.length}.rule`,
                                                            {
                                                                action: values.listRules[index].action,
                                                                rule: values.listRules[index].rule,
                                                                newElement: true },
                                                            setFieldValue
                                                        );
                                                    }}
                                                >
                                                    {renderIcon('copy')}
                                                </span>
                                                <span
                                                    onClick={() => {
                                                        if(values.listRules[index].rules_id) {
                                                            remove(values.listRules[index].rules_id);
                                                        }else{
                                                            arrayHelpers.pop();
                                                            setChangedRulesRows([...changedRulesRows.filter(row => row.index !== index)]);
                                                        }
                                                     }}
                                                    >
                                                    {renderIcon('trash')}
                                                </span>
                                            </div>
                                        </div>
                                    );
                                })}



                            </div>
                            <ReactTooltip backgroundColor='#222222' arrowColor='#222222' />
                        </div>
                    )}
                />
            </Form>
        )}
    </Formik>
    );
};


LiveActionRules.prototype = {
    listRules: PropTypes.array,
    localRules: PropTypes.array,
    localActions: PropTypes.array,
    setIsRulesChanged: PropTypes.func,
    isRulesChanged: PropTypes.bool,
    changedRulesRows: PropTypes.array,
    setChangedRulesRows: PropTypes.func,
};


export default LiveActionRules;