import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import Pagination from '../../../ui/pagination';
import Loader from '../../../ui/loader';
import moment from 'moment';
import { bindActionCreators } from 'redux';

import authorizationHeaders from '../../../../utils/authorizationHeaders';

import { heightLineTrBillingTable, getCountLines } from '../../../../constants/app';

import { useWindowWidth } from '../../../../utils/useWindowWidth';

import {
    getAllInvoice,
} from '../../../../actions/billing';

const InvoiceTable = ({
    actions: {
        getAllInvoice,
    },
    invoiceInfo: { records: invoices, total },
    invoiceLoading,
}) => {
    const handleGetJson = (id) => {
        fetch(`${process.env.REACT_APP_HOST}invoices/${id}`, {
            headers: {
                ...authorizationHeaders,
            },
        })
            .then((response) => {
                if (response.headers.get('Content-Type').indexOf('json') > -1) return response.text();
                if (response.headers.get('Content-Type').indexOf('pdf') > -1) return response.blob();
            })
            .then((response) => {
                window.open('#/receipt/' + id);
            });
    };

    const { width: windowWidth } = useWindowWidth();

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(0);

    const table = useRef(null);
    const tableHeader = useRef(null);

    useEffect(() => {
        const invoiceRowCount = getCountLines(
            table.current.clientHeight,
            tableHeader.current.clientHeight,
            heightLineTrBillingTable,
            parseInt(getComputedStyle(tableHeader.current).paddingTop)
        );

        setPageSize(invoiceRowCount);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [windowWidth]);

    useEffect(() => {
        getAllInvoice(pageSize, page);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageSize, page]);

    return (
        <div className='billing-box'>
            <div className='title'>Invoices log:</div>
            {!invoiceLoading && invoices?.length === 0 && (
                <div className='default-value'>There are no invoices.</div>
            )}
            <div className='scroll-table' ref={table}>
                <div className='invoice-table'>
                    <div className='childScroll'>
                        {invoiceLoading && (
                            <div className='loading-box'>
                                <Loader orange />
                            </div>
                        )}
                        <div className='head' ref={tableHeader}>
                            {invoices?.length !== 0 && (
                                <>
                                    <div className='number'>Invoice Number</div>
                                    <div className='date'>Date</div>
                                    <div className='amount'>Amount</div>
                                </>
                            )}
                        </div>
                        {invoices?.map((item, index) => (
                            <div className='line' key={index}>
                                <div className='number'><b onClick={() => handleGetJson(item.id)}>{item.id}</b>
                                </div>
                                <div className='date'>{moment(item.date).format('MMMM DD, YYYY')}</div>
                                <div className='amount'>$ {item.total}</div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className='pagination-box'>
                <Pagination
                    handleClick={setPage}
                    total={total ? Math.ceil(parseInt(total) / pageSize) : 0}
                    current={page}
                />
            </div>
        </div>
    );
};

// eslint-disable-next-line require-jsdoc
function mapStateToProps(state) {
    const {
        invoice, invoiceLoading,
    } = state.billing;

    return {
        invoiceInfo: invoice,
        invoiceLoading,
    };
}

// eslint-disable-next-line require-jsdoc
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            getAllInvoice,
        }, dispatch),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(InvoiceTable);