import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as PropTypes from "prop-types";
import classNames from "classnames";

import "./styles.scss";

import {
  getAddress,
  deleteAddress,
  updateAddress,
  updateBillingCheckbox,
  createShippingAddress,
  clearBillinAddress,
} from "../../../../actions/settings";

import Modal from "../../modal";
import BillingAddressForm from "../../../pages/settings/companyData/billingAddressForm";
import ShippingAddressFrom from "../../../pages/settings/companyData/shippingAddressFrom";
import "./styles.scss";

const defaultFields = {
  first_name: "",
  last_name: "",
  phone: "",
  alt_phone: "",
  email: "",
  country: "",
  zip_code: "",
  city: "",
  address: "",
  alt_address: "",
};

const EditOrganization = ({
  actions: {
    updateAddress,
    getAddress,
    deleteAddress,
    updateBillingCheckbox,
    createShippingAddress,
  },
  billingAddress,
  loading,
  updateLoading,
  closeModal,
  companyInfo,
}) => {
  const [shippingForms, setShippingForms] = useState([]);
  const [useBillingAddress, setUseBillingAddress] = useState(
    billingAddress.use_billing_address || 0
  );
  const [disabled, setDisable] = useState(false);
  const wrapperRef = useRef(null);
  const [flag, setFlag] = useState(false);
  const { organizationName, companyId } = companyInfo;
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(clearBillinAddress());
    };
  }, []);

  useEffect(() => {
    getAddress(companyId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setFlag(!flag);
    }, 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setDisable((billingAddress.shipping?.length + 1 == shippingForms.length + 1));
  }, [shippingForms.length]);

  useEffect(() => {
    if (
      billingAddress &&
      billingAddress.shipping &&
      billingAddress.shipping.length
    ) {
      const newFormsList = [];

      billingAddress.shipping.forEach((shippingAddress) => {
        newFormsList.push({
          key: `line-${newFormsList.length + 1}`,
          values: shippingAddress,
        });
      });

      setShippingForms(newFormsList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billingAddress.shipping && billingAddress.shipping?.length]);

  const deleteCurrentForm = (id) => {
    setShippingForms(shippingForms.filter((item) => item.key !== id));
  };

  const handleRemoveAddress = (id, key) => {
    deleteCurrentForm(key);
    id && deleteAddress(id);
  };

  const close = () => {
    setFlag(!flag);
    setTimeout(() => {
      closeModal(false);
    }, 300);
  };

  return (
    <Modal flag={flag}>
      <div
        className={classNames({
          "organization-info-modal": true,
          open: flag,
        })}
        ref={wrapperRef}
      >
        <div className="close-btn" onClick={() => close()}>
          X
        </div>
        <div className="title">{`edit ${organizationName}`}</div>
        <hr />
        <div className="form-container organization-info">
          <div className="title">Billing Address:</div>

          {billingAddress && billingAddress.billing && (
            <BillingAddressForm
              billingAddress={billingAddress.billing}
              updateAddress={updateAddress}
              updateLoading={updateLoading}
              companyId={companyId}
            />
          )}
          <div className="title-with-button">
            <div className="title">Shipping Address:</div>
            <div
              className={classNames({
                "same-abs-square": billingAddress.use_billing_address,
              })}
            >
              <button
                disabled={disabled}
                className="add-shipping"
                onClick={() => {
                  if (
                    !billingAddress.use_billing_address &&
                    billingAddress.shipping?.length + 1 > shippingForms.length
                  ) {
                    setShippingForms([
                      ...shippingForms,
                      {
                        key: `line-${
                          shippingForms.length > 0
                            ? shippingForms[shippingForms.length - 1].key.slice(
                                5
                              ) *
                                1 +
                              1
                            : 1
                        }`,
                        values: defaultFields,
                      },
                    ]);
                  }
                }}
              >
                add new
              </button>
            </div>
          </div>
          <div className="form-shipping-content">
            {billingAddress && (
              <div className="check-field">
                <input
                  className="styled-checkbox"
                  id="billingAddress"
                  type="checkbox"
                  checked={billingAddress.use_billing_address}
                  name="use_billing_address"
                  onChange={() => {
                    setUseBillingAddress(!useBillingAddress);
                    updateBillingCheckbox(companyId, !useBillingAddress);
                  }}
                />
                <label htmlFor="billingAddress">Same as billing address</label>
              </div>
            )}
            {shippingForms.map((form, index) => (
              <div
                className={classNames({
                  "same-abs-square": billingAddress.use_billing_address,
                })}
                key={index}
              >
                <ShippingAddressFrom
                  organization={true}
                  shippingAddress={form}
                  removeAddress={handleRemoveAddress}
                  createShippingAddress={createShippingAddress}
                  companyId={companyId}
                  deleteCurrentForm={deleteCurrentForm}
                  updateAddress={updateAddress}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </Modal>
  );
};

EditOrganization.propTypes = {
  actions: PropTypes.shape({
    updateAddress: PropTypes.func,
    getAddress: PropTypes.func,
    deleteAddress: PropTypes.func,
    updateBillingCheckbox: PropTypes.func,
    createShippingAddress: PropTypes.func,
  }),
  billingAddress: PropTypes.shape({
    billing_address: PropTypes.object,
    shipping: PropTypes.object,
  }),
  loading: PropTypes.bool,
  updateLoading: PropTypes.bool,
};

/**
 *
 * @param state
 * @returns {{updateLoading: *, billingAddress: *, loading: boolean, user: *}}
 */
function mapStateToProps(state) {
  return {
    billingAddress: state.settings.billingAddress,
    loading: state.settings.loadingAddress,
    updateLoading: state.settings.updateAddress,
    user: state.user,
  };
}

/**
 *
 * @param dispatch
 * @returns {{actions: ({createShippingAddress: createShippingAddress, updateAddress: updateAddress, getAddress: getAddress, deleteAddress: deleteAddress, updateBillingCheckbox: updateBillingCheckbox}|ActionCreator<any>|ActionCreatorsMapObject<any>)}}
 */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        getAddress,
        updateAddress,
        updateBillingCheckbox,
        createShippingAddress,
        deleteAddress,
      },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditOrganization);
