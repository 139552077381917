import fetch from 'cross-fetch';
import history from './history';
import authorizationHeaders from "./authorizationHeaders";
// import { refreshToken } from '../actions/auth';

/**
 *
 * @param endPoint
 * @param method
 * @param data
 * @returns {Promise<Response | never>}
 */
function callApi(endPoint, method, data) {
    const authHeaders = authorizationHeaders();
    const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...authHeaders,
    };

    // const localToken = localStorage.getItem('authToken') || sessionStorage.getItem('authToken');
    //
    // if (localToken && (Math.round(Date.now() / 1000) >= JSON.parse(localToken).date + 3600)) {
    //
    //         refreshToken();
    // }
    data instanceof FormData && delete headers['Content-Type'];
    return fetch(`${process.env.REACT_APP_HOST}${endPoint}`,{
        method,
        headers,
        body: data instanceof FormData ? data : JSON.stringify(data),
    }).then(response => {
        if (response.status === 200) {
            return response.json();
        } else if (response.status === 204) {
            return response;
        } else if (response.status === 201) {
            return response.json();
        } else if (response.status === 401) {
            localStorage.clear();
            sessionStorage.clear();
            history.push('/login');
        } else {
            console.log('promise');

            return response.json().then(json => Promise.reject(json));
        }
    });
}

export {
    callApi,
};
