import * as types from '../constants';

export const initialState = {
    devices: [],
    error: false,
    loading: true,
    statuses: [],
    device:{},
    loadingDevice: true,
    tags:{},
    loadingLiveAction: false,
    sms: {},
    totalSms: 0,
    loadingSms: true,
    sendSms: '',
    loadingSendSms: false,
    otherInfo: [],
    loadingOtherInfo: true,
    loadingAddOtherInfo: false,
    uploadingDevices: false,
    manageLog: {},
    loadingStatus: false,
    loadingManageLog: true,
};

/**
 *
 * @param state
 * @param action
 * @returns {*}
 */
export default function devices(state = initialState, action) {
    switch (action.type) {
        case types.FETCH_ADD_DEVICE:
            return {
                ...state,
                sendingNewDevice: true,
            };
        case types.FETCH_ADD_DEVICES:
            return {
                ...state,
                sendingNewDevices: true,
            };
        case types.FETCH_DEVICES:
        case types.FETCH_DEVICE_STATUS:
            return {
                ...state,
                loadingStatus: true,
            };
        case types.FETCH_CHANGE_STATUS_DEVICES:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case types.FETCH_GET_MANAGE_LOG:
            return {
                ...state,
                loadingManageLog: true,
            };
        case types.FETCH_OTHER_INFO:
        case types.FETCH_DELETE_OTHER_INFO:
        case types.FETCH_UPDATE_OTHER_INFO:
            return {
                ...state,
                loadingOtherInfo: true,
                error: false,
            };
        case types.FETCH_CSV_ASSIGN_DEVICES:
            return {
                ...state,
                uploadingDevices: true,
            };
        case types.FETCH_ADD_OTHER_INFO:
            return {
                ...state,
                loadingAddOtherInfo: true,
            };
        case types.FETCH_SEND_SMS:
            return {
                ...state,
                loadingSendSms: true,
            };
        case types.FETCH_REMOVE_LIVE_ACTION_DEVICE:
        case types.FETCH_ADD_LIVE_ACTION:
            return {
                ...state,
                loadingLiveAction: true,
            };
        case types.FETCH_GET_SMS:
            return {
                ...state,
                loadingSms: true,
                error: false,
            };
        case types.FETCH_DEVICE:
        case types.FETCH_COMPANIES_DATA:
        case types.FETCH_CHANGE_STATUS_DEVICE:
        case types.FETCH_CHANGE_PLAN_DEVICE:
        case types.FETCH_GET_TAGS:
        case types.FETCH_ADD_TAG:
        case types.FETCH_REMOVE_TAG:
            return {
                ...state,
                loadingDevice: true,
            };
        case types.FETCH_OTHER_INFO_SUCCESS:
            return {
                ...state,
                otherInfo: action.payload,
                loadingOtherInfo: false
            }
        case types.FETCH_DELETE_OTHER_INFO_SUCCESS:
            return {
                ...state,
                otherInfo: state.otherInfo.filter(item => item.id !== action.payload),
                loadingOtherInfo: false,
            };
        case types.FETCH_UPDATE_OTHER_INFO_SUCCESS:
            return {
                ...state,
                otherInfo: state.otherInfo.map(item => item.id === action.payload.id ? action.payload : item),
                loadingOtherInfo: false,
            }
        case types.FETCH_CSV_ASSIGN_DEVICES_SUCCESS:
            return {
                ...state,
                uploadingDevices: false,
            };
        case types.FETCH_GET_SMS_SUCCESS:
            return {
                ...state,
                sms: action.payload,
                totalSms: action.payload.total,
                loadingSms: false,
            };
        case types.FETCH_ADD_OTHER_INFO_SUCCESS:
            return {
                ...state,
                otherInfo: [action.payload, ...state.otherInfo],
                loadingAddOtherInfo: false,
            };
        case types.FETCH_DEVICES_SUCCESS:
            return {
                ...state,
                devices: action.payload,
                loading: false,
                error: false,
            };
        case types.FETCH_GET_MANAGE_LOG_SUCCESS:
            return {
                ...state,
                manageLog: action.payload,
                loadingManageLog: false,
            };
        case types.FETCH_CHANGE_STATUS_DEVICE_SUCCESS:
        case types.FETCH_CHANGE_PLAN_DEVICE_SUCCESS:
            return {
                ...state,
                device: action.payload,
                loadingDevice: false,
                error: false,
            };
        case types.FETCH_SEND_SMS_SUCCESS:
            return {
                ...state,
                sendSms: action.payload,
                loadingSendSms: false,
                error: false,
            };
        case types.FETCH_RESET_SEND_SMS:
            return {
                ...state,
                sendSms: '',
                error: false,
            };
        case types.FETCH_REMOVE_LIVE_ACTION_DEVICE_SUCCESS:
        case types.FETCH_ADD_LIVE_ACTION_SUCCESS:
            return {
                ...state,
                device: action.payload,
                loadingLiveAction: false,
            };
        case types.FETCH_ADD_DEVICE_SUCCESS:
            return {
                ...state,
                sendingNewDevice: false,
            };
        case types.FETCH_ADD_DEVICES_SUCCESS:
            return {
                ...state,
                sendingNewDevices: false,
            };
        case types.FETCH_CHANGE_STATUS_DEVICES_SUCCESS:
            return {
                ...state,
                devices: {
                    ...state.devices,
                    records: [
                        ...state.devices.records.map(device => {
                            action.payload.forEach(changedDevice => {
                                if (device.id === changedDevice.id) {
                                    device = changedDevice
                                }
                            })
                            return device;
                        }),
                    ],
                },
                loading: false,
                error: false,
            };
        case types.FETCH_GET_TAGS_SUCCESS:
            return {
                ...state,
                loadingDevice: false,
                tags: action.payload,
            };
        case types.FETCH_ADD_TAG_SUCCESS:
        case types.FETCH_REMOVE_TAG_SUCCESS:
            return {
                ...state,
                device: action.payload,
                loadingDevice: false,
            };
        case types.FETCH_DEVICE_STATUS_SUCCESS:
            return {
                ...state,
                statuses: action.payload,
                error: false,
                loadingStatus: false,
            };
        case types.FETCH_COMPANIES_DATA_SUCCESS:
            return {
                ...state,
                companies: action.payload,
                error: false,
                loadingStatus: false,
            };
        case types.FETCH_DEVICE_SUCCESS:
            return {
                ...state,
                device: action.payload,
                loadingDevice: false,
            };
        case types.FETCH_DEVICES_FAIL:
        case types.FETCH_CHANGE_STATUS_DEVICE_FAIL:
        case types.FETCH_COMPANIES_DATA_FAIL:
        case types.FETCH_CHANGE_STATUS_DEVICES_FAIL:
        case types.FETCH_CHANGE_PLAN_DEVICE_FAIL:
        case types.FETCH_GET_TAGS_FAIL:
        case types.FETCH_ADD_TAG_FAIL:
        case types.FETCH_REMOVE_TAG_FAIL:
            return {
                ...state,
                loading: false,
                loadingDevice: false,
                error: true,
            };
        case types.FETCH_ADD_DEVICE_FAIL:
            return {
                ...state,
                sendingNewDevice: false,
                error: true,
            };
        case types.FETCH_ADD_DEVICES_FAIL:
            return {
                ...state,
                sendingNewDevices: false,
                error: true,
            };
        case types.FETCH_CSV_ASSIGN_DEVICES_FAIL:
            return {
                ...state,
                uploadingDevices: false,
                error: true,
            };
        case types.FETCH_REMOVE_LIVE_ACTION_DEVICE_FAIL:
        case types.FETCH_ADD_LIVE_ACTION_FAIL:
            return {
                ...state,
                loadingLiveAction: true,
            };
        case types.FETCH_SEND_SMS_FAIL:
            return {
                ...state,
                loadingSendSms: false,
                error: true,
            };
        case types.FETCH_ADD_OTHER_INFO_FAIL:
            return {
                ...state,
                loadingAddOtherInfo: false,
                error: true,
            };
        case types.FETCH_GET_SMS_FAIL:
            return {
                ...state,
                loadingSms: false,
                error: true,
            };
        case types.FETCH_OTHER_INFO_FAIL:
        case types.FETCH_DELETE_OTHER_INFO_FAIL:
        case types.FETCH_UPDATE_OTHER_INFO_FAIL:
            return {
                ...state,
                loadingOtherInfo: false,
                error: true,
            };
        case types.FETCH_GET_MANAGE_LOG_FAIL:
            return {
                ...state,
                loadingManageLog: false,
                error: true,
            };
        case types.FETCH_DEVICE_STATUS_FAIL:
            return {
                ...state,
                loadingStatus: false,
            };
        default:
            return state;
    }
};