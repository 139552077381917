import React from 'react';
import PageContainer from '../../../ui/pageContainer';
import Breadcrumbs from '../../../ui/breadcrumbs';
import UploadInputDevice from '../uploadInputDevice';
import UploadInputDeviceMobile from "../uploadInputDevice/UploadInputDeviceMobile";
import {isMobile} from "../../../../constants/app";

import './styles.scss';

const UploadDevices = () => {

    return (
        <div className='upload-devices'>
            <div className="devices-header">
                <div className="header-container">
                    <Breadcrumbs/>
                </div>
            </div>
            <PageContainer>
                {
                    isMobile
                        ?
                        <UploadInputDeviceMobile />
                        :
                        <div className="content">
                            <UploadInputDevice />
                        </div>
                }
            </PageContainer>
        </div>
    );
};

export default UploadDevices;