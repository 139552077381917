import * as types from '../constants';

export const initialState = {
    plans: {},
    sendFormSuccess: null,
    formLoading: false,
    error: false,
    loading: true,
};

/**
 *
 * @param state
 * @param action
 * @returns {*}
 */
export default function plans(state = initialState, action) {
    switch (action.type) {
        case types.FETCH_PLANS:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case types.FETCH_CUSTOM_PLAN:
            return {
                ...state,
                formLoading: true,
                error: false,
            };
        case types.FETCH_PLANS_SUCCESS:
            return {
                ...state,
                plans: action.payload,
                loading: false,
                error: false,
            };
        case types.FETCH_CUSTOM_PLAN_SUCCESS:
            return {
                ...state,
                sendFormSuccess: action.payload,
                formLoading: false,
                error: false,
            };
        case types.FETCH_RESET_CUSTOM_PLAN:
            return {
                ...state,
                sendFormSuccess: null,
            };
        case types.FETCH_PLANS_FAIL:
        case types.FETCH_CUSTOM_PLAN_FAIL:
            return {
                ...state,
                loading: false,
                formLoading: false,
                error: true,
            };
        default:
            return state;
    }
};
