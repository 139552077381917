export const FETCH_GET_LIVE_ACTION_DEVICE = 'FETCH_GET_LIVE_ACTION_DEVICE';
export const FETCH_GET_LIVE_ACTION_DEVICE_SUCCESS = 'FETCH_GET_LIVE_ACTION_DEVICE_SUCCESS';
export const FETCH_GET_LIVE_ACTION_DEVICE_FAIL = 'FETCH_GET_LIVE_ACTION_DEVICE_FAIL';

export const FETCH_GET_ALL_LIVE_ACTIONS = 'FETCH_GET_ALL_LIVE_ACTIONS';
export const FETCH_GET_ALL_LIVE_ACTIONS_SUCCESS = 'FETCH_GET_ALL_LIVE_ACTIONS_SUCCESS';
export const FETCH_GET_ALL_LIVE_ACTIONS_FAIL = 'FETCH_GET_ALL_LIVE_ACTIONS_FAIL';

export const FETCH_SET_LIVE_ACTION = 'FETCH_SET_LIVE_ACTION';
export const FETCH_SET_LIVE_ACTION_SUCCESS = 'FETCH_SET_LIVE_ACTION_SUCCESS';
export const FETCH_SET_LIVE_ACTION_FAIL = 'FETCH_SET_LIVE_ACTION_FAIL';

export const FETCH_GET_LIVE_ACTION = 'FETCH_GET_LIVE_ACTION';
export const FETCH_GET_LIVE_ACTION_SUCCESS = 'FETCH_GET_LIVE_ACTION_SUCCESS';
export const FETCH_GET_LIVE_ACTION_FAIL = 'FETCH_GET_LIVE_ACTION_FAIL';

export const FETCH_UPDATE_LIVE_ACTION = 'FETCH_UPDATE_LIVE_ACTION';
export const FETCH_UPDATE_LIVE_ACTION_SUCCESS = 'FETCH_UPDATE_LIVE_ACTION_SUCCESS';
export const FETCH_UPDATE_LIVE_ACTION_FAIL = 'FETCH_UPDATE_LIVE_ACTION_FAIL';


export const FETCH_GET_ALL_RULES = 'FETCH_GET_ALL_RULES';
export const FETCH_GET_ALL_RULES_SUCCESS = 'FETCH_GET_ALL_RULES_SUCCESS';
export const FETCH_GET_ALL_RULES_FAIL = 'FETCH_GET_ALL_RULES_FAIL';

export const FETCH_GET_ALL_ACTIONS = 'FETCH_GET_ALL_ACTIONS';
export const FETCH_GET_ALL_ACTIONS_SUCCESS = 'FETCH_GET_ALL_ACTIONS_SUCCESS';
export const FETCH_GET_ALL_ACTIONS_FAIL = 'FETCH_GET_ALL_ACTIONS_FAIL';

export const FETCH_SET_RULE_ACTION = 'FETCH_SET_RULE_ACTION';
export const FETCH_SET_RULE_ACTION_SUCCESS = 'FETCH_SET_RULE_ACTION_SUCCESS';
export const FETCH_SET_RULE_ACTION_FAIL = 'FETCH_SET_RULE_ACTION_FAIL';

export const FETCH_UPDATE_RULE_ACTION = 'FETCH_UPDATE_RULE_ACTION';
export const FETCH_UPDATE_RULE_ACTION_SUCCESS = 'FETCH_UPDATE_RULE_ACTION_SUCCESS';
export const FETCH_UPDATE_RULE_ACTION_FAIL = 'FETCH_UPDATE_RULE_ACTION_FAIL';

export const FETCH_REMOVE_RULE_ACTION = 'FETCH_REMOVE_RULE_ACTION';
export const FETCH_REMOVE_RULE_ACTION_SUCCESS = 'FETCH_REMOVE_RULE_ACTION_SUCCESS';
export const FETCH_REMOVE_RULE_ACTION_FAIL = 'FETCH_REMOVE_RULE_ACTION_FAIL';

export const FETCH_REMOVE_LIVE_ACTIONS = 'FETCH_REMOVE_LIVE_ACTIONS';
export const FETCH_REMOVE_LIVE_ACTIONS_SUCCESS = 'FETCH_REMOVE_LIVE_ACTIONS_SUCCESS';
export const FETCH_REMOVE_LIVE_ACTIONS_FAIL = 'FETCH_REMOVE_LIVE_ACTIONS_FAIL';
