import React from 'react';
import { createPortal } from 'react-dom';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';

import './styles.scss';

const Modal = ({ children, flag }) => {
    return createPortal(
        (
            <div className={classNames({ 'bg-overlay': true, open: flag })}>
                {children}
            </div>
        ),
        document.getElementById('modal')
    );
};

Modal.propTypes = {
    children: PropTypes.any,
    flag: PropTypes.bool,
};

export default Modal;
