import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorMessage, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';

import AuthLayout from '../authLayout';
import { login, closeModal } from '../../../../actions/auth';
import InputBox from '../../../../components/ui/inputBox';
import Loader from '../../../ui/loader';
import Verification from '../../../ui/modals/verification';

import './styles.scss';

const Login = ({ isOpenedVerification }) => {
    const dispatch = useDispatch();

    const loading = useSelector(state => state.auth.loginLoading);
    const { state } = useLocation();
    const message = state?.message;

    const [remember, setRemember] = useState(false);
    const handleRemember = () => setRemember(!remember);

    const close = () => {
        dispatch(closeModal())
    };

    return (
        <AuthLayout>
            { isOpenedVerification && (<Verification closeModal={close}/>)}
            <Formik
                initialValues={{
                    username: '',
                    password: '',
                }}
                validationSchema={
                    Yup.object().shape({
                        username: Yup.string().required('User name is required'),
                        password: Yup.string().required('Password is required'),
                    })
                }
                onSubmit={field => {
                    dispatch(login(field, remember));
                }}
            >
                {({ setFieldValue }) => (
                    <Form>
                        <div className='form-box'>
                            <div className='title'>Welcome to Mobile Asset Solutions</div>
                            <div className='description'>Please sign in to view tracking information</div>
                            <div className='form-name'>
                                {message ?? 'Existing user? Log in below' }
                            </div>
                            <div className='input-box'>
                                <InputBox
                                    label='Username'
                                    icon='men'
                                    name='username'
                                    type='text'
                                    setFieldValue={setFieldValue}
                                />
                                <ErrorMessage name='username' className='error-field' component='div' />
                            </div>
                            <div className='input-box'>
                                <InputBox
                                    label='Password'
                                    icon='lockBlack'
                                    name='password'
                                    type='password'
                                    setFieldValue={setFieldValue}
                                />
                                <ErrorMessage name='password' className='error-field' component='div' />
                            </div>
                            <div className='options'>
                                <div className='check-box'>
                                    <input
                                        onChange={handleRemember}
                                        className='styled-checkbox'
                                        id='remember-me'
                                        type='checkbox'
                                        checked={remember}
                                    />
                                    <label htmlFor='remember-me'>Remember me</label>
                                </div>
                                 {/*<div className='forgot' onClick={() => ('forgotPassword')}>Forgot Password?</div>*/}
                                <Link className='forgot' to='/forgot-password'>Forgot Password?</Link>
                            </div>
                            <div className='btn-link'>
                                {loading && (
                                    <div className='loading-square'>
                                        <div className='box'>
                                            <Loader />
                                        </div>
                                    </div>
                                )}
                                <button type='submit'>Log in</button>
                                {/*<div className='sign' onClick={() => ('signUp')}>Don’t have an account? <span>Sign Up</span></div>*/}
                                <div className='sign'>Don’t have an account?
                                    <Link to='/signup'> <span>Sign Up</span></Link>
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </AuthLayout>
    );
};
const mapStateToProps = (state) => {
    return {
        isOpenedVerification: state.auth.isOpenedVerification,
    };
};


export default connect(mapStateToProps)(Login);
