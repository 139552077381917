import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';
import { bindActionCreators } from 'redux';

import { renderIcon } from '../../../utils/renderIcon';

import { getUser } from '../../../actions/user';
import { logOut } from '../../../actions/auth';

import {hasPermission} from "../../../constants/permissions";

import menus from '../../../constants/menu';
import logo from '../../../assets/svg/logo.svg';

import './styles.scss';

const Sidebar = ({ actions: { getUser, logOut }, isActive, closeMenu, user }) => {
    const [token, setToken] = useState(null);

    useEffect(() => {
        getUser(JSON.parse(sessionStorage.getItem('userInfo') || localStorage.getItem('userInfo')).user_id)
        setToken(JSON.parse(sessionStorage.getItem('authToken') || localStorage.getItem('authToken')).access_token)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    return (
        <>
            <div className={classNames({ sidebar: true, open: isActive })}>
                <div className='head'>
                    <Link to='/'>
                        <img src={logo} alt='logo'/>
                    </Link>
                </div>
                <div className='info'>
                    {user && Object.keys(user).length > 1 ? (
                        <>
                            <div className='name'>{`${user.first_name} ${user.last_name}`}</div>
                            <div className='email'>{user.email}</div>
                        </>
                    ) : null}
                </div>
                <ul className='menu'>
                    {menus.map(({ name, icon, link }, index) => {
                        const Icon = icon;

                        return hasPermission(user?.role, link) && (
                            <li key={index}>
                                <NavLink to={link} activeClassName='selected'>
                                    <div className='icon'>
                                        <Icon />
                                    </div>
                                    <div className='text'>{name}</div>
                                </NavLink>
                            </li>
                        );
                    })}
                </ul>
                <div className='logout' onClick={() => logOut(token) }>
                    <div className='icon'>{renderIcon('logout')}</div>
                    <div className='text'>Log out</div>
                </div>
            </div>
            <div className={classNames({ overlay: true, active: isActive })} onClick={closeMenu}/>
        </>
    );
};

Sidebar.propTypes = {
    isActive: PropTypes.bool,
    closeMenu: PropTypes.func,
    user: PropTypes.object,
    actions: PropTypes.shape({
        getUser: PropTypes.func,
        logOut: PropTypes.func,
    }),
};

/**
 *
 * @param state
 * @returns {{user: (user.userInfo|{role, company_id, user_id, phone, billing_id, permissions, last_name, first_name, email, username}|props.user.userInfo|((options: {encoding: "buffer"}) => UserInfo<Buffer>)|((options?: {encoding: string}) => UserInfo<string>)|{user_id}|*)}}
 */
function mapStateToProps(state) {
    return {
        user: state.user.userInfo,
    };
}

/**
 *
 * @param dispatch
 * @returns {{actions: ({getUser: getUser, logOut: logOut}|ActionCreator<any>|ActionCreatorsMapObject<any>)}}
 */
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            getUser,
            logOut,
        }, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
