import * as types from '../constants';
import { callApi } from '../utils/client';
import history from '../utils/history';
import { NotificationManager } from 'react-notifications';

const showNotification = (message, type) => {
    const parsedMessage = JSON.stringify(message);
    if (type === 'error') {
        NotificationManager.error('Something went wrong', undefined, 3000);
    } else if (type === 'success') {
        NotificationManager.success('Record successfully updated', undefined, 3000);
    }
};

/**
 *
 * @param id
 * @param callback
 * @returns {Function}
 */
export function deleteAddress(id, callback = () => {}) {
    return dispatch => {
        dispatch({
            type: types.FETCH_DELETE_ADDRESS,
        });


        return callApi(`address/${id}`,'DELETE')
            .then(() => {
                dispatch({
                    type: types.FETCH_DELETE_ADDRESS_SUCCESS,
                    payload: id,
                });
                callback();
            }).catch(error => {
            dispatch({
                type: types.FETCH_DELETE_ADDRESS_FAIL,
                payload: error.message,
            });
        });
    };
}


/**
 *
 * @param field
 * @returns {Function}
 */
export function createShippingAddress(field) {
    return dispatch => {
        dispatch({
            type: types.FETCH_CREATE_BILLING_ADDRESS,
        });

        return callApi('address','POST', field)
            .then(json => {
                dispatch({
                    type: types.FETCH_CREATE_BILLING_ADDRESS_SUCCESS,
                    payload: json,
                });
            }).catch(error => {
            dispatch({
                type: types.FETCH_CREATE_BILLING_ADDRESS_FAIL,
                payload: error.message,
            });
        });
    };
}
/**
 *
 * @param id
 * @param use_billing_address
 * @returns {Function}
 */
export function updateBillingCheckbox(id = 1, use_billing_address) {
    return dispatch => {
        dispatch({
            type: types.FETCH_UPDATE_BILLING_CHECKBOX,
        });

        return callApi(`company/${id}`,'PUT', { use_billing_address })
            .then(json => {
                dispatch({
                    type: types.FETCH_UPDATE_BILLING_CHECKBOX_SUCCESS,
                    payload: json,
                });
            }).catch(error => {
            dispatch({
                type: types.FETCH_UPDATE_BILLING_CHECKBOX_FAIL,
                payload: error.message,
            });
        });
    };
}

/**
 *
 * @param id
 * @param values
 * @returns {Function}showNotification(json, 'success');
 */
export function updateAddress(id = 1, values) {
    return dispatch => {
        dispatch({
            type: types.FETCH_UPDATE_ADDRESS,
        });

        return callApi(`address/${id}`,'PUT', values)
            .then(json => {
                dispatch({
                    type: types.FETCH_UPDATE_ADDRESS_SUCCESS,
                    payload: json.data,
                });
                showNotification(json.message, 'success');
            }).catch(error => {
            dispatch({
                type: types.FETCH_UPDATE_ADDRESS_FAIL,
                payload: error.message,
            });
            showNotification(error, 'error');
        });
    };
}

/**
 *
 * @returns {Function}
 */
export function getAddress(id = 1) {
    return dispatch => {
        dispatch({
            type: types.FETCH_BILLING_ADDRESS,
        });

        return callApi(`company/${id}/address`, 'GET')
            .then(json => {
                dispatch({
                    type: types.FETCH_BILLING_ADDRESS_SUCCESS,
                    payload: json,
                });
            }).catch(error => {
            dispatch({
                type: types.FETCH_BILLING_ADDRESS_FAIL,
                payload: error.message,
            });
        });
    };
}

export function clearBillinAddress() {
    return dispatch => {
        dispatch({
          type: types.CLEAR_BILLING_ADDRESS,
        });
    };
}

/**
 *
 * @param id
 * @param size
 * @param page
 * @returns {Function}
 */
export function getApiAccess(id = 1, size = 1, page = 0) {
    return dispatch => {
        dispatch({
            type: types.FETCH_API_ACCESS,
        });

        return callApi(`company/${id}/api-access?size=${size}&page=${page}`, 'GET')
            .then(json => {
                dispatch({
                    type: types.FETCH_API_ACCESS_SUCCESS,
                    payload: json,
                });
            }).catch(error => {
            dispatch({
                type: types.FETCH_API_ACCESS_FAIL,
                payload: error.message,
            });
        });
    };
}

/**
 *
 * @param id
 * @returns {Function}
 */
export function removeApiAccess(id = 0) {
    return dispatch => {
        dispatch({
            type: types.FETCH_REMOVE_API_ACCESS,
        });

        return callApi(`api-access/${id}`, 'DELETE')
            .then(json => {
                dispatch({
                    type: types.FETCH_REMOVE_API_ACCESS_SUCCESS,
                    payload: id,
                });
            }).catch(error => {
            dispatch({
                type: types.FETCH_REMOVE_API_ACCESS_FAIL,
                payload: error.message,
            });
        });
    };
}


/**
 * @returns {Function}
 */
export function removeAllApiAccessExceptCurrent() {
    return dispatch => {
        dispatch({
            type: types.FETCH_REMOVE_ALL_API_ACCESS_EXCEPT_CURRENT,
        });

        return callApi('api-access/clear-api-keys', 'DELETE')
        .then( () => {
            dispatch({
                type: types.FETCH_REMOVE_ALL_API_ACCESS_EXCEPT_CURRENT_SUCCESS,
            });
        }).catch(error => {
            dispatch({
                type: types.FETCH_REMOVE_ALL_API_ACCESS_EXCEPT_CURRENT_FAIL,
                payload: error.message,
            });
        });
    };
}
